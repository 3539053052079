import { IElementImg360 } from "@faro-lotv/ielement-types";
import { LodPano } from "@faro-lotv/lotv";
import { GroupProps, useFrame, useLoader, useThree } from "@react-three/fiber";
import { forwardRef, useMemo } from "react";
import { LinearFilter, Texture, TextureLoader, Vector2 } from "three";
import { useLotvDispose } from "../../hooks";
import { UPDATE_LOD_STRUCTURES_PRIORITY } from "../../utils/on-before-render-priorities";
import { createPanoLodTree } from "./img-360-lod-utils";

/**
 * Interface of ImageDetails
 */
interface IImageDetails {
  /** number of the slices in x direction*/
  dimX: number;

  /** number of the slices in y direction*/
  dimY: number;

  /** Array of urls for each tile of the sliced 360 */
  sources: string[];
}

/**
 * Method try to parse the given JSON string
 *
 * @param jsonString that have to parse by this function
 * @returns undefined or an IImageDetails item
 */
function tryParse(jsonString: string | undefined): IImageDetails | undefined {
  if (!jsonString) {
    return;
  }
  try {
    return JSON.parse(jsonString);
  } catch {
    // This function is meant to catch the error so it can be ignored
    return undefined;
  }
}

// Preventing the creation of new Vector2 on every render by adding a module scoped variable
const vector2 = new Vector2();

type Image360PanoBaseProps = {
  /** The pano to render */
  pano: LodPano;

  /** The opacity for this pano */
  opacity?: number;

  /**
   * Define to true to register this as a secondary view for split screen rendering
   *
   * @default false
   */
  isSecondaryView?: boolean;
} & GroupProps;

export type Img360PanoRef = LodPano | undefined;

/**
 * @returns A renderer for LodPano images, do not manage resources
 *
 * Do not own the pano resources, they need to be disposed externally from this component
 * @see Lotv.safeDispose
 */
export const Img360PanoBase = forwardRef<Img360PanoRef, Image360PanoBaseProps>(
  function Img360PanoBase(
    {
      pano: sourcePano,
      opacity,
      isSecondaryView = false,
      ...rest
    }: Image360PanoBaseProps,
    ref,
  ): JSX.Element {
    const pano = useMemo(
      () => (isSecondaryView ? sourcePano.createView() : sourcePano),
      [isSecondaryView, sourcePano],
    );
    useLotvDispose(isSecondaryView ? pano : null);
    const gl = useThree((state) => state.gl);
    const camera = useThree((state) => state.camera);

    const size = gl.getSize(vector2);

    // Update the visible tiles on each frame change
    useFrame(() => {
      /**
       * - The updateVisibleNodes looks at the current viewport of the canvas and calculates the visible nodes
       * - It also handles the level of depth of tiles i.e the high resolution tile is loaded at the center first
       * - The size variable provides the visible area of the 360 image which
       *  in turn helps in calculating the visible tiles
       */
      pano.updateCamera(camera, size.multiplyScalar(gl.getPixelRatio()));
      // calling this hook with UPDATE_LOD_STRUCTURES_PRIORITY so it is executed
      // after the camera has been moved by controls and animations.
    }, UPDATE_LOD_STRUCTURES_PRIORITY);

    return (
      <group {...rest}>
        <primitive object={pano} ref={ref} opacity={opacity} dispose={null} />
      </group>
    );
  },
);

interface Image360PanoProps {
  /** The pano to render */
  iElement: IElementImg360;
  /** The opacity for this pano */
  opacity?: number;
}

/**
 * @returns A component to load, manage resource and render a LodPano
 */
export function Img360Pano({
  iElement,
  opacity,
}: Image360PanoProps): JSX.Element | null {
  const texture = useImg360Texture({ iElement });
  const pano = useMemo(
    () => new LodPano(createPanoLodTree(iElement), texture),
    [iElement, texture],
  );
  useLotvDispose(pano);
  return <Img360PanoBase pano={pano} opacity={opacity} />;
}

/**
 * Hook to fetch the texture from an Img360 iElement
 *
 * Handling of 360 pano when viewed in panorama mode
 * Uses the LodPano class to handle the level of depth
 *
 * @returns Texture component
 */
function useImg360Texture({
  iElement,
}: Pick<Image360PanoProps, "iElement">): Texture {
  // This texture is used in the placeholder sphere
  const uriToLoad = tryParse(iElement.json1x1)?.sources[0] ?? iElement.uri;
  const texture = useLoader(TextureLoader, uriToLoad);

  // The handling of resolution of tiles is done by LotV class
  // No need for threejs to do the mipmap generation
  texture.generateMipmaps = false;
  texture.minFilter = LinearFilter;
  texture.needsUpdate = true;

  return texture;
}
