import { Typography, TypographyProps, useTheme } from "@mui/material";
import { CSSProperties, ElementType, memo } from "react";
import { ColorString, blue, cyan, neutral, red } from "../../colors";
import { FontWeights } from "../../faro-theme";

/** Available variants of the faro text inline with the design */
export enum FaroTextVariant {
  heading50 = "heading50",
  heading32 = "heading32",
  heading24 = "heading24",
  heading20 = "heading20",
  heading16 = "heading16",
  heading14 = "heading14",
  heading12 = "heading12",
  bodyL = "bodyL",
  bodyM = "bodyM",
  bodyS = "bodyS",
  bodyXS = "bodyXS",
  hyperLink = "hyperLink",
  buttonL = "buttonL",
  buttonM = "buttonM",
  buttonS = "buttonS",
  inlineError = "inlineError",
  helpText = "helpText",
  labelL = "labelL",
  labelM = "labelM",
  labelS = "labelS",
  placeholder = "placeholder",
}

enum FaroTextVariantResponsive {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

type ResponsiveStyleValue = Partial<
  Record<`${FaroTextVariantResponsive}`, `${FaroTextVariant}`>
>;

// NOTE: Using type instead of interface trigger an error (Expression produces a union type that is too complex to represent)
export interface FaroTextProps
  extends Omit<TypographyProps, "variant" | "variantMapping"> {
  /** Available variants of the faro text in line with the design */
  variant: `${FaroTextVariant}` | ResponsiveStyleValue;

  /** The component within which the text should be displayed. ex - div, p, span */
  component?: ElementType;

  /** True if the text ellipsis should be applied */
  shouldElide?: boolean;

  /** Use the dark version */
  dark?: boolean;
}

/**
 * custom text component with predefined variants which are in line with faro design
 *
 * @returns A custom text component with predefined variants which are in line with faro design
 */
export const FaroText = memo(function FaroText({
  variant,
  children,
  component = "span",
  sx,
  fontSize,
  fontWeight,
  lineHeight,
  color,
  shouldElide,
  dark,
  ...rest
}: FaroTextProps): JSX.Element {
  const theme = useTheme();

  // Construct the variant style based on the variant prop
  // If the variant is a string, use the corresponding style from the map
  // Else, construct the style based on the responsive variant map
  const variantSx =
    typeof variant === "string"
      ? mapVariantToCss(variant, dark)
      : {
          ...(variant.xs && {
            [theme.breakpoints.down("xs")]: mapVariantToCss(variant.xs, dark),
          }),
          ...(variant.sm && {
            [theme.breakpoints.up("sm")]: mapVariantToCss(variant.sm, dark),
          }),
          ...(variant.md && {
            [theme.breakpoints.up("md")]: mapVariantToCss(variant.md, dark),
          }),
          ...(variant.lg && {
            [theme.breakpoints.up("lg")]: mapVariantToCss(variant.lg, dark),
          }),
          ...(variant.xl && {
            [theme.breakpoints.up("xl")]: mapVariantToCss(variant.xl, dark),
          }),
        };

  // Props that would otherwise be overwritten by the variantSx
  const propSx = {
    ...(color ? { color } : undefined),
    ...(fontSize ? { fontSize } : undefined),
    ...(fontWeight ? { fontWeight } : undefined),
    ...(lineHeight ? { lineHeight } : undefined),
  };

  return (
    <Typography
      className={`FaroText-${variant}`}
      component={component}
      sx={{
        ...variantSx,
        ...propSx,
        ...(shouldElide && {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }),
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Typography>
  );
});

const LIGHT_COLORS = {
  heading: neutral[800],
  body: neutral[800],
  link: blue[500],
  button: blue[500],
  error: red[500],
  help: neutral[600],
  label: neutral[800],
  placeholder: neutral[600],
} satisfies Record<string, ColorString>;

const DARK_COLORS = {
  heading: neutral[100],
  body: neutral[100],
  link: cyan[400],
  button: cyan[400],
  error: red[300],
  help: neutral[300],
  label: neutral[100],
  placeholder: neutral[300],
} satisfies Record<string, ColorString>;

function mapVariantToCss(
  variant: `${FaroTextVariant}`,
  dark?: boolean,
): CSSProperties {
  switch (variant) {
    default:
      throw Error(`Variant ${variant} not supported by FaroText`);
    case FaroTextVariant.heading50:
      return {
        fontSize: "3.125rem",
        fontWeight: FontWeights.Bold,
        lineHeight: "3.875rem",
        color: dark ? DARK_COLORS.heading : LIGHT_COLORS.heading,
      };
    case FaroTextVariant.heading32:
      return {
        fontSize: "2rem",
        fontWeight: FontWeights.Bold,
        lineHeight: "2.75rem",
        color: dark ? DARK_COLORS.heading : LIGHT_COLORS.heading,
      };
    case FaroTextVariant.heading24:
      return {
        fontSize: "1.5rem",
        fontWeight: FontWeights.SemiBold,
        lineHeight: "2.25rem",
        color: dark ? DARK_COLORS.heading : LIGHT_COLORS.heading,
      };
    case FaroTextVariant.heading20:
      return {
        fontSize: "1.25rem",
        fontWeight: FontWeights.SemiBold,
        lineHeight: "1.875rem",
        color: dark ? DARK_COLORS.heading : LIGHT_COLORS.heading,
      };
    case FaroTextVariant.heading16:
      return {
        fontSize: "1rem",
        fontWeight: FontWeights.SemiBold,
        lineHeight: "1.5rem",
        color: dark ? DARK_COLORS.heading : LIGHT_COLORS.heading,
      };
    case FaroTextVariant.heading14:
      return {
        fontSize: "0.875rem",
        fontWeight: FontWeights.SemiBold,
        lineHeight: "1.25rem",
        color: dark ? DARK_COLORS.heading : LIGHT_COLORS.heading,
      };
    case FaroTextVariant.heading12:
      return {
        fontSize: "0.75rem",
        fontWeight: FontWeights.Bold,
        lineHeight: "1rem",
        color: dark ? DARK_COLORS.heading : LIGHT_COLORS.heading,
      };
    case FaroTextVariant.bodyL:
      return {
        fontSize: "1rem",
        fontWeight: FontWeights.Regular,
        lineHeight: "1.5rem",
        color: dark ? DARK_COLORS.body : LIGHT_COLORS.body,
      };
    case FaroTextVariant.bodyM:
      return {
        fontSize: "0.875rem",
        fontWeight: FontWeights.Regular,
        lineHeight: "1.25rem",
        color: dark ? DARK_COLORS.body : LIGHT_COLORS.body,
      };
    case FaroTextVariant.bodyS:
      return {
        fontSize: "0.75rem",
        fontWeight: FontWeights.Regular,
        lineHeight: "1rem",
        color: dark ? DARK_COLORS.body : LIGHT_COLORS.body,
      };
    case FaroTextVariant.bodyXS:
      return {
        fontSize: "0.625rem",
        fontWeight: FontWeights.Regular,
        lineHeight: "0.75rem",
        color: dark ? DARK_COLORS.body : LIGHT_COLORS.body,
      };
    case FaroTextVariant.hyperLink:
      return {
        fontSize: "0.875rem",
        fontWeight: FontWeights.SemiBold,
        lineHeight: "1.25rem",
        color: dark ? DARK_COLORS.link : LIGHT_COLORS.link,
        textDecoration: "underline",
      };
    case FaroTextVariant.buttonL:
      return {
        fontSize: "1rem",
        fontWeight: FontWeights.SemiBold,
        lineHeight: "1.5rem",
        color: dark ? DARK_COLORS.button : LIGHT_COLORS.button,
      };
    case FaroTextVariant.buttonM:
      return {
        fontSize: "0.875rem",
        fontWeight: FontWeights.SemiBold,
        lineHeight: "1.5rem",
        color: dark ? DARK_COLORS.button : LIGHT_COLORS.button,
      };
    case FaroTextVariant.buttonS:
      return {
        fontSize: "0.75rem",
        fontWeight: FontWeights.SemiBold,
        lineHeight: "1.5rem",
        color: dark ? DARK_COLORS.button : LIGHT_COLORS.button,
      };
    case FaroTextVariant.inlineError:
      return {
        fontSize: "0.75rem",
        fontWeight: FontWeights.Regular,
        lineHeight: "1.125rem",
        color: dark ? DARK_COLORS.error : LIGHT_COLORS.error,
      };
    case FaroTextVariant.helpText:
      return {
        fontSize: "0.75rem",
        fontWeight: FontWeights.Regular,
        lineHeight: "1.125rem",
        color: dark ? DARK_COLORS.help : LIGHT_COLORS.help,
      };
    case FaroTextVariant.labelL:
      return {
        fontSize: "0.875rem",
        fontWeight: FontWeights.Regular,
        lineHeight: "1.5rem",
        color: dark ? DARK_COLORS.label : LIGHT_COLORS.label,
      };
    case FaroTextVariant.labelM:
      return {
        fontSize: "0.75rem",
        fontWeight: FontWeights.Bold,
        lineHeight: "1.125rem",
        color: dark ? DARK_COLORS.label : LIGHT_COLORS.label,
      };
    case FaroTextVariant.labelS:
      return {
        fontSize: "0.625rem",
        fontWeight: FontWeights.Bold,
        lineHeight: "1rem",
        color: dark ? DARK_COLORS.label : LIGHT_COLORS.label,
      };
    case FaroTextVariant.placeholder:
      return {
        fontSize: "0.875rem",
        fontWeight: FontWeights.Regular,
        lineHeight: "1rem",
        color: dark ? DARK_COLORS.placeholder : LIGHT_COLORS.placeholder,
      };
  }
}
