import {
  FaroThemeOptions,
  IFaroThemePalette,
  IFaroThemePaletteOptions,
} from "@faro-lotv/flat-ui";
import { createTheme } from "@mui/material/styles";
import { merge } from "es-toolkit";

/**
 * To declare custom properties in the theme,
 * extending the interface of material styles
 *
 * @see - https://mui.com/material-ui/customization/palette/#typescript-2
 */
declare module "@mui/material/styles" {
  /**
   * Disabling the EsLint as the Palette only needs to extend the
   * ICustomPalette and nothing else need to be defined in this interface
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends IFaroThemePalette {}

  /** extending the type of the Palette by typing the custom properties added */
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends IFaroThemePaletteOptions {}
}

const appThemeOptions = merge(FaroThemeOptions, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: "100%",
          margin: 0,
        },
        body: {
          height: "100%",
          margin: 0,
        },

        "#root": {
          height: "100%",
        },
      },
    },
  },
});

/**
 * Material UI Theme containing the app specific style adjustments
 */
export const appTheme = createTheme(appThemeOptions);
