#include <packing>

varying vec2 vUv;

uniform sampler2D map;

uniform float opacity;
uniform float uBackgroundTransparent;

#include <clipping_planes_pars_fragment>
#include <lotv_color_conversions>

void main() {
    #include <clipping_planes_fragment>
    vec4 texColor = texture2D(map, vUv);

    float alpha = 1.0;
    if (uBackgroundTransparent != 0.0) {
        // Calculate relative luminance
        float luminance = dot(texColor.rgb, extractLuma);
        if (uBackgroundTransparent == 1.0) {
            // Invert luminance to set alpha (white -> 0.0, black -> 1.0)
            alpha = 1.0f - luminance;
        } else {
            // Apply luminance threshold, alpha = 1 to pixels with luminance < 0.75
            alpha = min(1.0, 4.0 * (1.0 - luminance));
        }
    }

    // Combine the transparency to preserve completely transparent sections of the image
    // while having a smooth transition
    gl_FragColor = texColor;
    gl_FragColor.a = alpha * texColor.a * opacity;

    // If the floorplan is fully transparent, discard the fragment
    // so e.g. the measure lines can be seen behind it.
    if(gl_FragColor.a < 0.05) discard;
}
