import { TypedEvent } from "@faro-lotv/foundation";
import { Camera, Color, Group, Light, Object3D, Scene, WebGLRenderTarget, WebGLRenderer } from "three";
import { Pass } from "three-stdlib";

/**
 * An alternative to RenderPass to render only a filtered view of a scene
 */
export class FilteredRenderPass extends Pass {
	needsSwap = false;

	/**
	 * Signal emitted when the pass is about to be rendered. It can be used to customize
	 * specific props on the objects that are about to be rendered.
	 */
	beforeRender = new TypedEvent<void>();

	/** Use a completely transparent background when rendering the scene, if clearing is enabled */
	transparentBackground = false;

	/** Private variable to avoid re-allocation every render */
	#clearColor: Color = new Color();

	/**
	 * A EffectComposer Pass to render on the read buffer a filtered view of a scene
	 *
	 * @param scene The scene to render
	 * @param camera The camera used to render the scene
	 * @param filter The filter to decide what should rendered
	 * @param clear True to clear the color buffer
	 * @param clearDepth True to clear the depth buffer
	 */
	constructor(
		public scene: Scene,
		public camera: Camera,
		public filter: (object: Object3D) => boolean = () => true,
		public clear = true,
		public clearDepth = true,
	) {
		super();
	}

	/**
	 * @inheritdoc
	 */
	render(renderer: WebGLRenderer, writeBuffer: WebGLRenderTarget, readBuffer: WebGLRenderTarget): void {
		this.beforeRender.emit();

		// Store away the renderer autoClear flas as we won't use it
		const oldAutoClear = renderer.autoClear;
		const oldBackground = this.scene.background;
		renderer.autoClear = false;

		renderer.setRenderTarget(this.renderToScreen ? null : readBuffer);

		// Hide all filtered objects and store their original visibility flag
		const visMap = new Map<Object3D, boolean>();
		this.scene.traverse((o) => {
			if (o === this.scene || o instanceof Group || o instanceof Light) return;
			visMap.set(o, o.visible);
			o.visible = o.visible && this.filter(o);
		});

		// Clear if needed
		const clearColor = renderer.getClearColor(this.#clearColor);
		const clearAlpha = renderer.getClearAlpha();
		if (this.clear || this.clearDepth) {
			if (this.transparentBackground) {
				renderer.setClearColor(0x000000, 0);
				this.scene.background = null;
			}
			renderer.clear(this.clear, this.clearDepth, false);
		} else {
			this.scene.background = null;
		}

		// Render the filtered scene
		renderer.render(this.scene, this.camera);

		// Restore original visibility flags
		for (const [object, visibility] of visMap.entries()) {
			object.visible = visibility;
		}

		// Restore original autoClear flag
		renderer.autoClear = oldAutoClear;
		this.scene.background = oldBackground;
		renderer.setClearColor(clearColor, clearAlpha);
	}
}
