import { useAppSelector } from "@/store/store-hooks";
import { assert } from "@faro-lotv/foundation";
import { IElement } from "@faro-lotv/ielement-types";
import { selectIElementWorldTransform } from "@faro-lotv/project-source";
import { useEffect, useState } from "react";
import { CanvasTexture, Texture } from "three";

// Red/Green/Blue colors for the gizmo to match the R3F camera gizmo on the bottom left of the dev tools view
const AXIS_COLORS = ["#ff2060", "#20df80", "#2080ff"];

export type ElementPoseRendererProps = {
  element: IElement;
};

/** @returns a simple gizmo to show an element world pose */
export function ElementPoseRenderer({
  element,
}: ElementPoseRendererProps): JSX.Element {
  const pose = useAppSelector(selectIElementWorldTransform(element.id));
  const [colorX, colorY, colorZ] = AXIS_COLORS;

  return (
    <group {...pose} name={element.id}>
      <Axis color={colorX} rotation={[0, 0, 0]} />
      <Axis color={colorY} rotation={[0, 0, Math.PI / 2]} />
      <Axis color={colorZ} rotation={[0, -Math.PI / 2, 0]} />
      <Label>{element.name}</Label>
    </group>
  );
}

type AxisProps = {
  color: string;
  rotation: [number, number, number];
};

function Axis({ color, rotation }: AxisProps): JSX.Element {
  return (
    <group rotation={rotation}>
      <mesh position={[1, 0, 0]}>
        <boxGeometry args={[2, 0.1, 0.1]} />
        <meshBasicMaterial color={color} />
      </mesh>
    </group>
  );
}

type LabelProps = {
  children: string;
};

function Label({ children }: LabelProps): JSX.Element | null {
  const [texture, setTexture] = useState<Texture | null>(null);
  const [width, setWidth] = useState(1);

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
    assert(canvas && ctx);

    const { width } = ctx.measureText(children);
    // Value found by trial and error to make the texture appear correctly on the sprite
    // with the default font
    setWidth(width / 100);

    canvas.width = width;
    canvas.height = 64;
    ctx.textAlign = "center";
    ctx.fillText(children, canvas.width / 2, 64);
    setTexture(new CanvasTexture(canvas));
  }, [children]);

  if (!texture) return null;

  return (
    // eslint-disable-next-line react/no-unknown-property
    <sprite scale-x={width}>
      <spriteMaterial map={texture} alphaTest={0.4} color="gray" />
    </sprite>
  );
}
