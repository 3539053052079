import { IElementImg360 } from "@faro-lotv/ielement-types";
import {
  ImageNodeFetch,
  ImageNodeOptions,
  ImageTree,
  ImageTreeNode,
} from "@faro-lotv/lotv";
import { Box2, Vector2 } from "three";
import { DEFAULT_IMAGE_FETCHER, Img360LodFetch } from "../img-360-lod-fetch";
import {
  LegacyIElementTilesLevels,
  parseLevel,
} from "./ielement360-level-utils";

/** The required property from an IElementImg360 used to create a IElement360Tree */
export type IElement360TreeData = Pick<
  IElementImg360,
  "pixelHeight" | "pixelWidth" | "json1x1" | "json2x1" | "json4x2" | "json8x4"
>;

/**
 * A subclass of ImageTree developed to parse HoloBuilder pano data.
 * It uses the legacy jsonXxY payloads that are now replaced by the levelsOfDetails structure
 *
 * @deprecated use @see Img360LodTree instead
 */
export class IElement360Tree extends ImageTree<string> {
  width: number;
  height: number;

  /** @returns the tree nodes */
  get nodes(): Array<ImageTreeNode<string> | undefined> {
    return this.nodesList;
  }

  /**
   * Constructs a new IElement360Tree
   *
   * @param data The data parsed from the HoloBuilder Json response
   * @param didLogTree Enables/disables debug output of tree to browser console
   */
  constructor(data: IElement360TreeData, didLogTree = false) {
    super();

    this.width = data.pixelWidth;
    this.height = data.pixelHeight;

    const rootLevel = parseLevel(data.json1x1);
    this.appendChildren(undefined, {
      source: rootLevel.sources[0],
      rect: new Box2(new Vector2(0, 0), new Vector2(1, 1)),
    });

    const root = this.getNode(0);
    if (data.json2x1) {
      this.appendToLeaves(root, this.computeRects(parseLevel(data.json2x1)));
    }
    if (data.json4x2) {
      this.appendToLeaves(root, this.computeRects(parseLevel(data.json4x2)));
    }
    if (data.json8x4) {
      this.appendToLeaves(root, this.computeRects(parseLevel(data.json8x4)));
    }

    if (didLogTree) {
      this.logTree();
    }
  }

  /**
   * Logs the tree structure
   */
  private logTree(): void {
    type QueueType = {
      node: ImageTreeNode<string>;
      indent: number;
    };

    const queue = new Array<QueueType>();
    queue.push({ node: this.getNode(0), indent: 1 });

    while (queue.length > 0) {
      const top = queue.shift();
      if (!top) {
        return;
      }

      const { indent, node } = top;

      const str = "--";
      const branch = new Array(indent).join(str);

      const fileExtLen = 4;
      const nodeDesc = node.source.slice(
        node.source.lastIndexOf("/") - fileExtLen,
      );

      // TODO: Re enable console warnings when we move to a proper logging system: https://faro01.atlassian.net/browse/SWEB-459
      // eslint-disable-next-line no-console
      console.log(str + branch + nodeDesc);

      if (node.children) {
        for (const child of node.children) {
          queue.push({ node: child, indent: indent + 1 });
        }
      }
    }
  }

  /**
   * Creates new leaf nodes for the tree by appending new nodes to the nodes that contain them
   *
   * @param parentNode The node to start the search for children,
   * call with root initially, but will be used to recursively find the leaves
   * @param source The lod level containing list of URLs that are the data sources
   * for the children as well as the dimensionality of it
   */
  private appendToLeaves(
    parentNode: ImageTreeNode<string>,
    source: Array<ImageNodeOptions<string>>,
  ): void {
    if (!parentNode.children) {
      const children = source.filter((v) =>
        parentNode.rect.containsPoint(v.rect.getCenter(new Vector2())),
      );
      this.appendChildren(parentNode, ...children);
      return;
    }
    for (const child of parentNode.children) {
      this.appendToLeaves(child, source);
    }
  }

  /**
   * Calculates the UV Rects for each image in a given level
   *
   * @param level The LOD Level
   * @returns {ImageNodeOptions } structure with appropriate UV rects calculated
   */
  computeRects(
    level: LegacyIElementTilesLevels,
  ): Array<ImageNodeOptions<string>> {
    const descs = new Array<ImageNodeOptions<string>>();
    const cols = level.dimX;
    const rows = level.dimY;

    for (let i = 0; i < cols * rows; ++i) {
      const col = Math.floor(i % cols);
      const row = rows - Math.floor(i / cols) - 1;
      const min = new Vector2(col / cols, row / rows);
      const max = new Vector2((col + 1) / cols, (row + 1) / rows);
      const desc: ImageNodeOptions<string> = {
        source: level.sources[i],
        rect: new Box2(min, max),
      };
      descs.push(desc);
    }
    return descs;
  }

  /**
   * Get the image for a node
   *
   * @param nodeOrId The node or the node id to fetch the image
   * @returns An object to wait for the image or cancel the fetch
   */
  override getNodeImage(
    nodeOrId: number | ImageTreeNode<string>,
  ): Promise<ImageNodeFetch> {
    const node = typeof nodeOrId === "number" ? this.nodes[nodeOrId] : nodeOrId;

    // Node might be undefined in case `nodeOrId` is an invalid ID
    if (!node) {
      throw new Error("Requested node does not exist");
    }
    return Promise.resolve(
      new Img360LodFetch(node, node.source, DEFAULT_IMAGE_FETCHER),
    );
  }
}
