import {
  ComposeFramebuffersPass,
  EffectPipeline,
  EffectPipelineWithSubScenes,
  SubScenePipeline,
} from "@faro-lotv/lotv";
import { Pass } from "three-stdlib";

/**
 * Function to be used in as the attach property in a <primitive> tag to attach a pass to an effect pipeline
 *
 * @param parent The parent node of the component, should be an EffectPipeline for attach to work
 * @param self The current pass node we want to attach
 * @returns the dispose function react will use to detach the pass
 */
export function attachPass(parent: unknown, self: Pass): () => void {
  if (!(parent instanceof EffectPipeline)) {
    throw new Error(
      "attachPass has to be called in a component child of an EffectPipeline",
    );
  }

  parent.addPass(self);
  return () => parent.removePass(self);
}

/**
 * Function to be used in as the attach property in a <primitive> tag to attach a SubScene to an EffectPipelineWithSubScenes
 *
 * @param parent The parent node of the component, should be an EffectPipelineWithSubScenes for attach to work
 * @param self The current sub scene we want to attach
 * @returns the dispose function react will use to detach the sub scene
 */
export function attachSubScene(
  parent: unknown,
  self: SubScenePipeline,
): () => void {
  if (
    !(parent instanceof EffectPipelineWithSubScenes) &&
    !(parent instanceof ComposeFramebuffersPass)
  ) {
    throw new Error(
      "attachSubScene has to be called in a component child of an EffectPipelineWithSubScene",
    );
  }

  parent.addSubScene(self);
  return () => parent.removeSubScene(self);
}
