/**
 * A callback used to traverse a data structure
 * value is the value to analyze
 * append is a function to append further values to the queue of values to check
 */
export type WalkCallback<T, Ret = void> = (
  value: T,
  append: (...toAppend: T[]) => void,
) => Ret | undefined;

/**
 * Walk a generic data structure with a queue to not use recursion
 *
 * @param queue The initial nodes of the data-structure to visit
 * @param compute A function that will take the value to compute and a callback to append more nodes to visit
 * @returns the value returned by the compute callback, or undefined if compute never returns anything
 */
export function walkWithQueue<T, Ret>(
  queue: Array<NotUndefined<T>>,
  compute: WalkCallback<NotUndefined<T>, Ret>,
): Ret | undefined {
  function append(...toQueue: Array<NotUndefined<T>>): void {
    queue.push(...toQueue);
  }
  let val = queue.shift();
  while (val !== undefined) {
    const ret = compute(val, append);
    if (ret) {
      return ret;
    }
    val = queue.shift();
  }
}

type NotUndefined<T> = T extends undefined ? never : T;
