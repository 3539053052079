import { isDownloadAbortError } from "@faro-lotv/foundation";
import { Camera, Texture, Vector2 } from "three";
import { TiledFloorPlan } from "../FloorPlan";
import { includes } from "../Utils/SortedArrays";
import { ImageNodeFetch, ImageTree, ImageTreeNode } from "./ImageTree";
import { NodeState } from "./LodTree";
import { VisibleTilesStrategy } from "./VisibleTilesStrategy";

export type LodFloorPlanOptions = {
	/** Maximum number of nodes downloaded in parallel */
	maxNodesToDownloadAtOnce: number;

	/** A texture used as overview */
	overviewTexture: Texture;
};

export const LOD_FLOOR_PLAN_DEFAULTS = {
	/**
	 * The maximum number of nodes whose image can be downloaded at once.
	 */
	maxNodesToDownloadAtOnce: 4,
};

type FloorplanCacheElement = {
	/** The node in the image tree */
	node: ImageTreeNode;
	/**
	 * Last time this node was downloaded to be rendered.
	 * Time is measured in milliseconds since creation of the application DOM document.
	 */
	lastRenderedTime: number;
};

/**
 * A class capable of rendering a level-of-detail tiled pano image.
 */
export class LodFloorPlan extends TiledFloorPlan {
	/** The  image tree */
	#tree: ImageTree;
	/**
	 * The list of indices of nodes that currently fall inside the camera frustum, sorted approximately by screen size occupancy from
	 * greatest to smallest. If a node is in this list, it is either waiting for download, downloading, or loaded in GPU.
	 */
	#currVisibleNodes = new Array<number>();
	/**
	 * The queue of nodes waiting for download.
	 */
	#nodesToDownloadQueue = new Array<number>();
	/**
	 * The list of nodes whose image is currently downloading, implemented as a map node idx -> LodNodeFetch object.
	 * Only '_maxNodesToDownloadAtOnce' nodes are allowed to be in this list.
	 */
	#downloadingNodes = new Map<number, ImageNodeFetch | null>();
	/**
	 * The list of nodes whose texture is currently loaded in GPU and rendered, implemented as a map node idx -> texture.
	 */
	#nodesInGPU = new Map<number, FloorplanCacheElement>();

	/**
	 * The list of nodes that are cached in memory, but not loaded to the GPU for rendering.
	 */
	#nodesInMemory = new Map<number, FloorplanCacheElement>();

	/**
	 * The total number of textures that are currently being rendered.
	 */
	#texturesInGPU = 0;

	/**
	 * Generic options for the tree management
	 */
	#options = { ...LOD_FLOOR_PLAN_DEFAULTS };

	/**
	 * Constructs an object responsible of rendering a Lod Tiled Pano
	 *
	 * @param tree The lod tree data structure.
	 * @param options Parameters for the LodPano.
	 */
	constructor(tree: ImageTree, options?: Partial<LodFloorPlanOptions>) {
		super(tree.maxDepth, options?.overviewTexture);
		this.#tree = tree;
		this.#options = { ...this.#options, ...options };
		this.matrixWorldNeedsUpdate = true;
	}

	/**
	 * Unloads all nodes in the 'toUnload' list.
	 * For each node n in the 'toUnload' list: if the node is in GPU, its textures are deleted from GPU.
	 * If instead the node is downloading, its download is canceled.
	 *
	 * @param toUnload The list of nodes that fell out of visibility and must be unloaded from GPU
	 */
	private unloadNodes(toUnload: number[]): void {
		for (const n of toUnload) {
			const node = this.tree.getNode(n);
			if (node.state === NodeState.InUse) {
				const element = this.#nodesInGPU.get(n);
				if (!element?.node.image) {
					throw new Error(`Expected node ${n} to be rendering!`);
				}

				this.#texturesInGPU--;

				this.removeTile(element.node.image);

				this.#nodesInGPU.delete(n);
			} else if (node.state === NodeState.Downloading) {
				const p = this.#downloadingNodes.get(n);
				if (p) {
					p.abort();
					this.#downloadingNodes.delete(n);
					this.processDownloads();
				}
			}
			node.state = NodeState.NotInUse;
		}
	}

	/**
	 * Upload nodes to the GPU if available, return nodes still missing
	 *
	 * @param nodeIds Array of node IDs that need to be shown. Each node that is in the cache is uploaded to the GPU.
	 * @returns The node indexes from the original array that were not in the cache.
	 */
	private showCachedNodes(...nodeIds: number[]): number[] {
		const unchachedNodes = new Array<number>();
		for (const nodeIdx of nodeIds) {
			const p = this.#nodesInMemory.get(nodeIdx);
			if (p) {
				if (this.#nodesInGPU.has(nodeIdx)) {
					// This is a bit of a hack, need to find out why we are trying to add a node that is already visible.
					console.warn(`tried resetting an existing index in _nodesInGPU. ${nodeIdx}`);
					continue;
				}
				if (!p.node.image) {
					console.error("tried to display a null image");
					continue;
				}

				this.addTile(p.node.image, p.node.rect, p.node.depth);

				this.#nodesInGPU.set(nodeIdx, p);
				this.#texturesInGPU++;
				this.#tree.getNode(nodeIdx).state = NodeState.InUse;
			} else {
				unchachedNodes.push(nodeIdx);
			}
		}
		return unchachedNodes;
	}

	/**
	 * Just after download, loads a node's Texture to GPU.
	 *
	 * @param nodeIdx Idx of node that is being loaded to GPU
	 * @param image Texture data to be loaded
	 */
	private handleImageDownloaded(nodeIdx: number, image: Texture): void {
		const node = this.#tree.getNode(nodeIdx);
		node.image = image;
		this.#nodesInMemory.set(nodeIdx, {
			node,
			lastRenderedTime: performance.now(),
		});

		if (this.isNodeVisible(nodeIdx)) {
			this.showCachedNodes(nodeIdx);
		}
	}

	/**
	 * After node n has been downloaded (or its download has been canceled), here we remove the LodNodeFetch object that
	 * supported the download. Also, we call 'processDownloads' again to check whether there are other nodes in queue
	 * that need to be downloaded.
	 *
	 * @param n The downloaded node.
	 */
	private cleanupDownload(n: number): void {
		this.#downloadingNodes.delete(n);
		this.processDownloads();
	}

	/**
	 * Logs download error to console if it is not deliberate cancellation.
	 *
	 * In this function, we filter out error messages that are deliberate cancellations of downloads,
	 * in order to not pollute the browser console.
	 *
	 * @param n The node that was downloading
	 * @param error The error message
	 */
	private handleDownloadError(n: number, error: Error | string): void {
		if (!isDownloadAbortError(error)) {
			console.log(`Error while downloading node ${n}:`);
			console.log(error);
		}
		this.tree.getNode(n).state = NodeState.NotInUse;
	}

	/**
	 * Main algorithm for node download and management.
	 */
	private processDownloads(): void {
		// Process the queue of nodes to download. Pop nodes from there into _downloadingNodes until _downloadingNodes is full.
		while (this.#nodesToDownloadQueue.length > 0 && this.#downloadingNodes.size < this.maxNodesToDownloadAtOnce) {
			// In the line below, the queue is not empty for sure, therefore we can safely expect to pop it.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const n = this.#nodesToDownloadQueue.shift() as number;
			const node = this.#tree.getNode(n);
			if (node.state !== NodeState.WaitingForDownload) {
				continue;
			}
			node.state = NodeState.Downloading;
			// Line below is needed to make sure that we never download at once more than the required amount of nodes.
			this.#downloadingNodes.set(n, null);
			this.#tree
				.getNodeImage(n)
				.then((f: ImageNodeFetch): Promise<Texture> => {
					const pp = f.image();
					// After heap memory profiling, the if below is very worth doing.
					// It is very possible, in fact, that while we wait for the LodNodeFetch object,
					// the node fell out of visibility already!
					if (this.isNodeVisible(n)) {
						this.#downloadingNodes.set(n, f);
					} else {
						f.abort();
						this.#downloadingNodes.delete(n);
					}
					return pp;
				})
				.then((image: Texture) => {
					// Got the texture: render it.
					this.handleImageDownloaded(n, image);
				})
				.catch((error: Error | string) => {
					// Log download error to console if it is not deliberate cancellation.
					this.handleDownloadError(n, error);
				})
				.finally(() => {
					// Either in case of successful download or error, or cancellation, we cleanup
					// the download handler and remove n from _downloadingNodes.
					this.cleanupDownload(n);
				});
		}
	}

	/**
	 * Moves all nodes in 'toLoad' to the GPU for rendering if they are already in the cache
	 * otherwise adds them to the queue of nodes waiting for download. Subsequently,
	 * processes the nodes to download queue.
	 *
	 * @param toLoad The list of nodes that came into visibility and must be loaded to GPU
	 */
	private loadNodes(toLoad: number[]): void {
		toLoad = this.showCachedNodes(...toLoad);
		for (const n of toLoad) {
			const node = this.tree.getNode(n);
			// we only enqueue nodes that are not yet enqueued for download.
			if (node.state === NodeState.NotInUse) {
				node.state = NodeState.WaitingForDownload;
				this.#nodesToDownloadQueue.push(n);
			}
		}
		this.processDownloads();
	}

	/**
	 *
	 * @param newVisibleNodes The new visible nodes for this update.
	 * @returns The list of nodes to load.
	 */
	private computeToLoadList(newVisibleNodes: number[]): number[] {
		const ret = new Array<number>();
		for (const n of newVisibleNodes) {
			if (!this.isNodeVisible(n)) {
				ret.push(n);
			}
		}
		return ret;
	}

	/**
	 *
	 * @param newVisibleNodes The new visible nodes for this update.
	 * @returns The list of nodes to unload
	 */
	private computeToUnloadList(newVisibleNodes: number[]): number[] {
		const sn = new Array<number>();
		sn.push(...newVisibleNodes);
		sn.sort((a: number, b: number) => a - b);
		const ret = new Array<number>();
		for (const n of this.#currVisibleNodes) {
			if (!includes(sn, n)) {
				ret.push(n);
			}
		}
		return ret;
	}

	/**
	 * This function should be called every time that the camera or the screen resolution changes.
	 * It recomputes which are the visible nodes of the LOD tree, and it consequently determines which new
	 * nodes should be loaded to GPU for rendering and which should be unloaded.
	 *
	 * @param camera The camera from which the cloud is being rendered
	 * @param screenSize The current screen resolution
	 */
	updateVisibleNodes(camera: Camera, screenSize: Vector2): void {
		const newVisibleNodes =
			this.#nodesInGPU.size === 0
				? [0]
				: this.visibleTilesStrategy.compute(this.matrixWorld, this.tree, camera, screenSize).map((w) => w.id);

		const toLoad = this.computeToLoadList(newVisibleNodes);
		const toUnload = this.computeToUnloadList(newVisibleNodes);
		this.unloadNodes(toUnload);
		if (toUnload.length > 0 || toLoad.length > 0) {
			this.#currVisibleNodes = newVisibleNodes;
		}
		this.loadNodes(toLoad);
	}

	/** @returns the LOD tree */
	get tree(): ImageTree {
		return this.#tree;
	}

	/** @returns The object responsible for computing the visible LOD nodes given a tree, a camera, and a screen resolution. */
	get visibleTilesStrategy(): VisibleTilesStrategy {
		return this.#tree.visibleTilesStrategy;
	}

	/**
	 * Returns whether the node 'node' is currently visible. If the node is visible,
	 * it does not mean that it is currently rendered, since it could be still downloading.
	 *
	 * @param node The index of the queried node
	 * @returns Whether 'node' is visible
	 */
	public isNodeVisible(node: number): boolean {
		return this.tree.getNode(node).state !== NodeState.NotInUse;
	}

	/** @returns how many tree nodes are visible from the current camera and resolution. They may not have all been downloaded yet. */
	get visibleNodesCount(): number {
		return this.#currVisibleNodes.length;
	}

	/** @returns how many tree nodes are currently loaded in GPU and being rendered. */
	get renderedNodesCount(): number {
		return this.#nodesInGPU.size;
	}

	/** @returns how many tree nodes are in memory whether they are rendered or not. */
	get cachedNodesCount(): number {
		return this.#nodesInMemory.size;
	}

	/** @returns how many tree nodes are currently being downloaded. */
	get downloadingNodesCount(): number {
		return this.#downloadingNodes.size;
	}

	/** @returns how many tree nodes are currently waiting to be downloaded. */
	get nodesWaitingForDownloadCount(): number {
		return this.#nodesToDownloadQueue.length;
	}

	/** @returns How many nodes can be downloaded simultaneously. */
	get maxNodesToDownloadAtOnce(): number {
		return this.#options.maxNodesToDownloadAtOnce;
	}

	/** Sets how many nodes can be download simultaneously. */
	set maxNodesToDownloadAtOnce(m: number) {
		if (m < 1 || m > 16) {
			console.log("Error: max nodes to download at once should lie in the interval [1, 16].");
		}
		this.#options.maxNodesToDownloadAtOnce = m;
	}

	/** @returns how many textures are being rendered right now. */
	get totTexturesInGPU(): number {
		return this.#texturesInGPU;
	}
}
