import { Stack } from "@mui/material";
import { memo } from "react";
import { green, neutral } from "../colors";
import { CheckmarkCircleFillIcon } from "../icons/icons";
import { FaroText } from "../text/faro-text/faro-text";

interface Props {
  /** The text description of the password rule */
  text: string;

  /** A boolean value indicating whether the password validation rule has failed. */
  isError: boolean;
}

/**
 * The PasswordValidationCheckerItem component is used to display the status of an individual password validation rule.
 * It consists of an icon and a text description that visually represents whether a specific password requirement has been met or not.
 */
export const PasswordValidationCheckerItem = memo(
  ({ text, isError }: Props) => (
    <Stack flexDirection="row" alignItems="center" gap="0.75rem">
      <CheckmarkCircleFillIcon
        data-testid="password-validation-icon"
        sx={{
          fontSize: "1.125rem",
          color: isError ? neutral[300] : green[700],
        }}
      />
      <FaroText variant="bodyS">{text}</FaroText>
    </Stack>
  ),
);

PasswordValidationCheckerItem.displayName = "PasswordValidationCheckerItem";
