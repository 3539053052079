import { IconContainerProps, Rating, Stack, styled } from "@mui/material";
import { blue, neutral } from "../colors";
import { HappyFaceIcon, RegularFaceIcon, UnhappyFaceIcon } from "../icons";
import { RateElement, RateElementProps } from "./rate-element";

export interface FeedbackRateProps {
  /** The initial value of the rate */
  value: number | null;

  /** Callback when the rate element is clicked */
  onChange(value: number | null): void;

  /**
   * Determines which type of feedback rate component should be used
   *
   * @default "emotion"
   */
  type?: "emotion" | "numbered";
}

/** The possible values for the rate */
enum RateValue {
  Unhappy = "NOT GOOD!",
  Okay = "OKAY",
  Great = "GREAT!",
}

/** The maximum rating that can be given */
const MAX_RATING = 5;

/** The options for the rate element */
export const RATE_OPTIONS: Array<Omit<RateElementProps, "onChange">> = [
  {
    label: RateValue.Unhappy,
    value: 1,
    iconComponent: <UnhappyFaceIcon sx={{ height: "44px", width: "44px" }} />,
  },
  {
    sx: { marginX: "12px" },
    label: RateValue.Okay,
    value: 3,
    iconComponent: <RegularFaceIcon sx={{ height: "44px", width: "44px" }} />,
  },
  {
    label: `${RateValue.Great}!`,
    value: MAX_RATING,
    iconComponent: <HappyFaceIcon sx={{ height: "44px", width: "44px" }} />,
  },
];

/**
 * The code snippet customizes the Material-UI Rating component using the styled function
 * from @mui/material/styles, creating a new component named StyledRating.
 */
const StyledRating = styled(Rating)(() => ({
  "&.feedback-rating": {
    gap: "32px",
    display: "flex",
    margin: "14px auto 0 auto",
  },
  "&.feedback-rating > label": {
    position: "relative",
  },
  "&.feedback-rating > label::after": {
    content: "''",
    position: "absolute",
    width: "32px",
    height: "1px",
    backgroundColor: neutral[200],
    top: "50%",
    transform: "translateY(-50%)",
    left: "100%",
  },
  "&.feedback-rating > label:nth-of-type(n + 5)::after": {
    display: "none",
  },
  "& .MuiRating-iconEmpty": {
    color: neutral[800],
  },
  "& .MuiRating-iconFilled.MuiRating-iconHover, .MuiRating-iconFilled, .MuiRating-iconEmpty":
    {
      minWidth: "50px",
      height: "50px",
      border: `1px solid ${neutral[200]}`,
      borderRadius: "100%",
      justifyContent: "center",
      alignItems: "center",
      transform: "scale(1)",
    },
  "& .MuiRating-iconFilled.MuiRating-iconHover, .MuiRating-iconFilled": {
    color: "white",
    background: blue[500],
    fontSize: "20px",
  },
}));

/**
 * IconContainer is a functional component responsible for rendering an icon based on the provided value.
 *
 * @param props - The props object containing the value to determine the icon.
 * @returns Returns a span element containing the corresponding icon based on the value.
 */
function IconContainer({ value, ...other }: IconContainerProps): JSX.Element {
  return <span {...other}>{value}</span>;
}

/**
 * @returns the rate component for feedback dialog
 */
export function FeedbackRate({
  value,
  onChange,
  type = "emotion",
}: FeedbackRateProps): JSX.Element {
  if (type === "numbered") {
    return (
      <StyledRating
        classes={{ root: "feedback-rating" }}
        IconContainerComponent={IconContainer}
        onChange={(_, value) => onChange(value ?? 1)}
        value={value}
        highlightSelectedOnly
      />
    );
  }
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {RATE_OPTIONS.map((rateOption) => (
        <RateElement
          key={rateOption.value}
          sx={rateOption.sx}
          label={rateOption.label}
          value={rateOption.value}
          iconComponent={rateOption.iconComponent}
          selectedValue={value}
          onChange={onChange}
        />
      ))}
    </Stack>
  );
}
