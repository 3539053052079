import { alpha, Box, BoxProps, Stack } from "@mui/material";
import { CSSProperties } from "@mui/material/styles/createMixins";
import { blue, neutral } from "../colors";

/** The class name for the label */
const RATE_LABEL_CLASS = "rateLabel";

/** The class name for the icon wrapper */
const RATE_ICON_WRAPPER_CLASS = "rateIconWrapper";

export interface RateElementProps {
  /** Label to show below the icon */
  label: string;

  /** The value to be returned when the rate element is clicked */
  value: number | null;

  /** Callback when the rate element is clicked */
  onChange(value: number | null): void;

  /** The icon component to show */
  iconComponent: JSX.Element;

  /** Optional selected value. If it is the same as value will show the item as selected */
  selectedValue?: number | null;

  /** Optional style to be applied to the root element */
  sx?: BoxProps["sx"];
}

/** Default CSS style to apply when the button is hovered or rate is selected. */
const hoverAndSelectedStyle: CSSProperties = {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  backgroundColor: alpha(neutral[500], 0.15),
  color: blue[500],
};

/**
 * @returns a rate element with an icon and a label.
 */
export function RateElement({
  label,
  selectedValue,
  value,
  onChange,
  iconComponent,
  sx,
}: RateElementProps): JSX.Element {
  /** Whether the rate element is selected */
  const isSelected = selectedValue === value;

  /** The properties to apply when the rate has been selected */
  const selectedStyle = isSelected ? hoverAndSelectedStyle : undefined;

  /** Reacts to clicks on the element */
  function onRateElementClick(): void {
    // If the value is the same as the selected value, then we want to deselect it
    if (isSelected) {
      onChange(null);
      return;
    }
    onChange(value);
  }

  return (
    <Box
      component="div"
      onClick={onRateElementClick}
      sx={{
        ...sx,
        cursor: "pointer",
        "&:hover": {
          [`& .${RATE_ICON_WRAPPER_CLASS}`]: {
            ...hoverAndSelectedStyle,
          },
          [`& .${RATE_LABEL_CLASS}`]: {
            visibility: "visible",
          },
        },
        "&:active": {
          [`& .${RATE_ICON_WRAPPER_CLASS}`]: {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            backgroundColor: alpha(neutral[500], 0.27),
            color: blue[500],
          },
        },
      }}
    >
      <Stack
        className={RATE_ICON_WRAPPER_CLASS}
        sx={{
          width: "64px",
          height: "64px",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          borderRadius: "50%",
          margin: 0,
          padding: 0,
          ...selectedStyle,
        }}
      >
        {iconComponent}
      </Stack>
      <Box
        component="div"
        className={RATE_LABEL_CLASS}
        sx={{
          visibility: isSelected ? "visible" : "hidden",
          color: isSelected ? blue[500] : neutral[600],
          fontWeight: "bold",
          textTransform: "uppercase",
          fontSize: "10px",
          marginTop: "8px",
          textAlign: "center",
        }}
      >
        {label}
      </Box>
    </Box>
  );
}
