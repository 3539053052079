import { useThree } from "@react-three/fiber";
import { useEffect } from "react";
import { Raycaster, Vector2 } from "three";
import { computePointerNdcCoordinates } from "../utils/event-utils";
import { useThreeEventTarget } from "./use-three-event-target";

export type CustomClickState = {
  /** The pointer release dom event triggering this click */
  event: PointerEvent;

  /** A raycaster properly configured to cast in the click direction using the current camera */
  raycaster: Raycaster;

  /** True if this was a double click */
  isDoubleClick: boolean;
};

/** Function to handle clicks */
export type CustomClickHandler = (state: CustomClickState) => void;

/** ms to wait to check for a double click (Using standard MS delay) */
const DOUBLE_CLICK_DELAY = 200;

/**
 * Hook to register a custom click event on the current event target with a prepared and cached raycaster
 * Call the handler only for clicks and not drags
 * Call the handler only one time with a flag if it was a click or a double click
 * Pass to the handler a raycaster already initialized correctly for the camera
 *
 * @param handler The function that will handle the click events
 */
export function useCustomClickEvent(handler: CustomClickHandler): void {
  const eventTarget = useThreeEventTarget();
  const camera = useThree((s) => s.camera);
  useEffect(() => {
    // Cached raycaster
    const raycaster = new Raycaster();

    // Pointer position at each mouse event
    const pointerDown = new Vector2();
    const pointerUp = new Vector2();
    let doubleClickTimeout: number | undefined;

    // Function to call the registered handler for a click event preparing the raycaster
    function callHandler(event: PointerEvent, isDoubleClick: boolean): void {
      doubleClickTimeout = undefined;
      const mouse = computePointerNdcCoordinates(event);
      raycaster.setFromCamera(mouse, camera);
      handler({ event, raycaster, isDoubleClick });
    }

    // Store pointer down position to check for drags
    function onPointerDown(event: PointerEvent): void {
      pointerDown.set(event.clientX, event.clientY);
    }

    function onPointerUp(event: PointerEvent): void {
      if (!(event.target instanceof HTMLCanvasElement)) return;
      // If it's a double click use the position of the first click and not the second
      if (doubleClickTimeout) {
        clearTimeout(doubleClickTimeout);
        doubleClickTimeout = undefined;
        callHandler(event, true);
        return;
      }

      pointerUp.set(event.clientX, event.clientY);
      if (pointerUp.distanceTo(pointerDown) > 0.1) return;
      // If it was a click then schedule the callHandler to leave time for a double click
      doubleClickTimeout = window.setTimeout(
        () => callHandler(event, false),
        DOUBLE_CLICK_DELAY,
      );
    }

    eventTarget.addEventListener("pointerdown", onPointerDown);
    eventTarget.addEventListener("pointerup", onPointerUp);

    return () => {
      eventTarget.removeEventListener("pointerdown", onPointerDown);
      eventTarget.removeEventListener("pointerup", onPointerUp);
    };
  }, [camera, eventTarget, handler]);
}
