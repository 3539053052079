/**
 * A binary heap that only supports the push and pop semantics.
 *
 * This is a faster data structure than BinaryHeap, because it does not keep a hash table
 * of elemnts IDs to their positions in the queue. Without this hash table, the semantic 'removeElement'
 * cannot be supported. BinaryHeap, therefore, is a slower and more versatile data structure. For simple
 * use cases that only need 'push' and 'pop' queue sematics, the present heap is faster.
 */
export class LeanBinaryHeap<T extends object> {
	/** The array storing the content of the heap */
	content: T[] = [];
	/** The callback used to compute the score of a given element */
	scoreFunction: (element: T) => number;

	/**
	 * Create an empty heap
	 *
	 * @param scoreFunction The function used to compute the element priority
	 */
	constructor(scoreFunction: (element: T) => number) {
		this.scoreFunction = scoreFunction;
	}

	/**
	 * Push an element in the heap
	 *
	 * @param element The new element
	 */
	push(element: T): void {
		// Add the new element to the end of the array.
		this.content.push(element);
		// Allow it to bubble up.
		this.bubbleUp(this.content.length - 1);
	}

	/**
	 * Remove the element with the highest priority from the heap
	 *
	 * @returns The element removed
	 */
	pop(): T {
		// Store the first element so we can return it later.
		const result = this.content[0];
		// Get the element at the end of the array.
		const end = this.content.pop();
		// If there are any elements left, put the end element at the
		// start, and let it sink down.
		if (this.content.length > 0 && end) {
			this.content[0] = end;
			// Allow the element to sink down the tree
			this.sinkDown(0);
		}
		return result;
	}

	/**
	 * Remove all elements from the binary heap
	 */
	clear(): void {
		this.content.length = 0;
	}

	/**
	 * @returns The number of elements in the heap
	 */
	get length(): number {
		return this.content.length;
	}

	/**
	 * Move an element upwards in the heap, until its right position is found
	 *
	 * @param n The index of the element
	 */
	bubbleUp(n: number): void {
		// Fetch the element that has to be moved.
		const element = this.content[n];
		const score = this.scoreFunction(element);
		// When at 0, an element can not go up any further.
		while (n > 0) {
			// Compute the parent element's index, and fetch it.
			const parentN = Math.floor((n + 1) / 2) - 1;
			const parent = this.content[parentN];
			// If the parent has a lesser score, things are in order and we
			// are done.
			if (score >= this.scoreFunction(parent)) break;

			// Otherwise, swap the parent with the current element and
			// continue.
			this.content[parentN] = element;
			this.content[n] = parent;
			n = parentN;
		}
	}

	/**
	 * Move an element downwards in the heap, until its right position is found.
	 *
	 * @param n The index of the element
	 */
	sinkDown(n: number): void {
		// Look up the target element and its score.
		const { length } = this.content;
		const element = this.content[n];
		const elemScore = this.scoreFunction(element);

		let swap = null;
		do {
			// Compute the indices of the child elements.
			const child2N = (n + 1) * 2;
			const child1N = child2N - 1;
			// This is used to store the new position of the element,
			// if any.

			swap = null;
			let child1Score = Number.POSITIVE_INFINITY;
			// If the first child exists (is inside the array)...
			if (child1N < length) {
				// Look it up and compute its score.
				const child1 = this.content[child1N];
				child1Score = this.scoreFunction(child1);
				// If the score is less than our element's, we need to swap.
				if (child1Score < elemScore) {
					swap = child1N;
				}
			}
			// Do the same checks for the other child.
			if (child2N < length) {
				const child2 = this.content[child2N];
				const child2Score = this.scoreFunction(child2);
				if (child2Score < (swap === null ? elemScore : child1Score)) {
					swap = child2N;
				}
			}

			// No need to swap further, we are done.
			if (swap === null) {
				break;
			}

			// Otherwise, swap and continue.
			this.content[n] = this.content[swap];
			this.content[swap] = element;
			n = swap;
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		} while (swap !== null);
	}

	/**
	 * Check that the underlying array in a binary heap actually represents
	 * a binary heap
	 */
	checkHeapSanity(): void {
		if (this.content.length === 0) return;
		for (let i = 0; i < this.content.length; ++i) {
			const minScore = this.scoreFunction(this.content[i]);
			const child1 = i * 2 + 1;
			const child2 = i * 2 + 2;
			if (child1 < this.content.length) {
				if (this.scoreFunction(this.content[child1]) < minScore) {
					throw new Error(
						`Parent score ${i}:${minScore} greater than child ${child1}:${this.scoreFunction(
							this.content[child1],
						)}`,
					);
				}
			}

			if (child2 < this.content.length) {
				if (this.scoreFunction(this.content[child2]) < minScore) {
					throw new Error(
						`Parent score ${i}:${minScore} greater than child ${child2}:${this.scoreFunction(
							this.content[child2],
						)}`,
					);
				}
			}
		}
	}
}
