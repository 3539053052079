import { Disposable } from "./disposable";
import { Listener } from "./listener";

/**
 * Class to emit events with a specific type and have type checked listeners
 */
export class TypedEvent<T> {
  private listeners: Array<Listener<T>> = [];
  private listenersOnce: Array<Listener<T>> = [];

  /**
   * Attach a listener for this event
   *
   * @param listener The listener function
   * @returns An object that can be used to disconnect this connection
   */
  on = (listener: Listener<T>): Disposable => {
    this.listeners.push(listener);
    return {
      dispose: () => this.off(listener),
    };
  };

  /**
   * Attach a listener that will receive the event only once
   *
   * @param listener The listener
   */
  once = (listener: Listener<T>): void => {
    this.listenersOnce.push(listener);
  };

  /**
   * Detach a listener
   *
   * @param listener the listener to detach
   */
  off = (listener: Listener<T>): void => {
    const callbackOnIndex = this.listeners.indexOf(listener);
    if (callbackOnIndex > -1) this.listeners.splice(callbackOnIndex, 1);

    const callbackOnceIndex = this.listenersOnce.indexOf(listener);
    if (callbackOnceIndex > -1) this.listenersOnce.splice(callbackOnceIndex, 1);
  };

  /**
   * Emit an event, will call all listeners
   *
   * @param event The event to forward to all listeners
   */
  emit = (event: T): void => {
    /** Update any general listeners */
    for (const listener of this.listeners) listener(event);

    /** Clear the `once` queue */
    if (this.listenersOnce.length > 0) {
      const toCall = this.listenersOnce;
      this.listenersOnce = [];
      for (const listener of toCall) listener(event);
    }
  };

  /**
   * Pipe all events to another emitter
   *
   * @param te The emitter to pipe all events to
   * @returns An object to detach the receiving emitter
   */
  pipe = (te: TypedEvent<T>): Disposable => this.on((e) => te.emit(e));

  /**
   * Wait for the next time the event is emitted
   *
   * @returns a promise that will resolve once the event is emitted
   */
  next = (): Promise<T> => new Promise<T>((success) => this.once(success));
}
