import { assert } from "./assert";
import { FetchResponseError, isNetworkConnectionError } from "./errors";
import { exponentialBackOff, retry } from "./retry";

/** HTTP status for too many requests */
const HTTP_TOO_MANY_REQUESTS = 429;

/** HTTP status indicating an internal server error */
const HTTP_INTERNAL_SERVER_ERROR = 500;

/**
 * A fetch wrapper that adds retry logic in case of network errors.
 * Should only be used with idempotent requests.
 *
 * @param input passed to the fetch() method
 * @param init passed to the fetch() method
 * @returns the response of the request
 */
export function robustFetch(
  input: string | URL | Request,
  init?: RequestInit,
): Promise<Response> {
  return retry(
    async () => {
      const res = await fetch(input, init);

      if (!res.ok) {
        throw new FetchResponseError(res);
      }

      return res;
    },
    {
      max: 3,
      delay: exponentialBackOff,
      shouldRetry: (error) =>
        // retry on unknown network errors (e.g. no internet, or closed connection)
        isNetworkConnectionError(error) ||
        (error instanceof FetchResponseError &&
          // retry, when the request was rate limited
          (error.response.status === HTTP_TOO_MANY_REQUESTS ||
            // retry on server errors
            error.response.status >= HTTP_INTERNAL_SERVER_ERROR)),
    },
  );
}

export type RobustFetchJsonParams<T> = {
  /** input passed to the fetch() method */
  input: string | URL | Request;

  /** init options passed to the fetch() method */
  init?: RequestInit;

  /** Validation type-guard for the response object */
  validateJson(obj: unknown): obj is T;
};

/**
 * A fetch wrapper that adds retry logic in case of network errors.
 * Should only be used with idempotent requests.
 *
 * @returns the validated json object of a successful response
 */
export async function robustFetchJson<T>({
  input,
  init,
  validateJson,
}: RobustFetchJsonParams<T>): Promise<T> {
  const res = await robustFetch(input, init);
  const json = await res.json();

  assert(validateJson(json), `Unexpected response for request: ${res.url}`);

  return json;
}
