import { useMemo } from "react";
import { TokenProvider } from "../authentication";
import { CoreApiClient } from "./core-api-client";

/**
 * @returns a CoreApiClient for with the given URL to use for calls
 * @param coreApiUrl The URL to call the Core API with
 * @param clientId A string to identify this client in the format client/version
 * @param sessionProvider A function to obtain a session cookie token
 */
export function useCoreApiClient(
  coreApiUrl: string,
  clientId?: string,
  sessionProvider?: TokenProvider,
): CoreApiClient {
  const coreApi = useMemo(
    () => new CoreApiClient(coreApiUrl, clientId, sessionProvider),
    [coreApiUrl],
  );

  return coreApi;
}
