import {
  ImageNodeFetch,
  ImageTreeNode,
  TextureFetchRequest,
  TextureLoader,
} from "@faro-lotv/lotv";
import {
  LinearFilter,
  MirroredRepeatWrapping,
  NearestFilter,
  Texture,
} from "three";

/** A function used to fetch the image for a node */
export type ImageFetcher = (url: string) => TextureFetchRequest;

/**
 * A default image fetcher that uses the browser api to fetch images
 *
 * @param url of the texture to fetch
 * @returns the promise and abort controller to control the fetch
 */
function defaultFetcher(url: string): TextureFetchRequest {
  return new TextureLoader().load(url);
}

/**
 * A default image fetcher that uses the browser api to fetch images
 *
 * NOTE: This implementation will work only in a browser context.
 * To use the components in a different context inject a different implementation
 * based for example on expo-three or other similar libraries depending on the context
 *
 * @param url of the texture to fetch
 * @returns the promise and abort controller to control the fetch
 */
export const DEFAULT_IMAGE_FETCHER: ImageFetcher = defaultFetcher;

/**
 * Node fetch class for an in-memory image LOD tree
 */
export class Img360LodFetch implements ImageNodeFetch {
  private controller: AbortController | undefined;

  /**
   * Constructor class that stores the node
   *
   * @param node The node itself.
   * @param url To use to fetch the node tile
   * @param fetcher Function to use to fetch the node image @default DEFAULT_IMAGE_FETCHER
   */
  constructor(
    private node: ImageTreeNode,
    private url: string,
    private fetcher: ImageFetcher = DEFAULT_IMAGE_FETCHER,
  ) {}

  /**
   * Return the image belonging to this node.
   *
   * @returns An already resolved promise.
   */
  async image(): Promise<Texture> {
    if (!this.node.image) {
      const { promise, abort } = this.fetcher(this.url);
      this.controller = abort;
      const texture = await promise;
      texture.wrapS = MirroredRepeatWrapping;
      texture.wrapT = MirroredRepeatWrapping;
      texture.minFilter = LinearFilter;
      texture.magFilter = NearestFilter;
      texture.generateMipmaps = false;
      texture.needsUpdate = true;
      this.node.image = texture;
    }
    return this.node.image;
  }

  /**
   * Function required for the LodNodeFetch interface.
   * Not used for in-memory LOD image tree.
   */
  abort(): void {
    this.controller?.abort();
  }
}
