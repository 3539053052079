import { FetchError } from "@faro-lotv/foundation";
import { EquirectangularDepthImage } from "../Pano/EquirectangularDepthImage";
import { TextureLoader } from "../Utils/TextureLoader";
import { loadWorker } from "../Utils/Workers";
import { WSCdnManager, WSCdnSignatures, isWSCdnSignatures } from "./WSCdnManager";
import { WSKDTree } from "./WSKDTree";
import { WSNodeFetch } from "./WSNodeFetch";
import { WSPanoProject } from "./WSPanoProject";
import { Convert as PointCloudConvert, WSPointCloudDesc } from "./responses/WSPointCloudDesc.gen";
import { Convert as ProjectConvert, WSProjectDesc } from "./responses/WSProjectDesc.gen";
import { Convert as ScanDescConvert, WSScanDesc } from "./responses/WSScanDesc.gen";
import { Convert as TreeNodeConvert } from "./responses/WSTreeNodeDesc.gen";

export type DepthRequest = {
	promise: Promise<EquirectangularDepthImage>;
	abort: AbortController;
};

/** The token to authenticate to webshare, just a string */
export type BearerToken = string;

/** A function that can be used to require a Token to talk to webshare */
export type TokenProvider = () => Promise<BearerToken>;

/**
 * A class to connect and query a webshare instance
 */
export class WSInstance {
	textureLoader = new TextureLoader();

	/**
	 * @param url The url of the webshare instance we want to talk to
	 * @param tokenProvider Function to get an authorization token for this backend
	 */
	constructor(
		public url: string,
		public tokenProvider?: TokenProvider,
	) {}

	/**
	 * Query webshare instance for all the available projects
	 *
	 * @returns a promise with all the available projects on this instance
	 */
	async getProjects(): Promise<WSProjectDesc[]> {
		const req = await this.#fetch(`${this.url}/project`);
		if (!req.ok) throw new FetchError("webshare", "project");
		const data = await req.text();
		return ProjectConvert.toWSProjectDesc(data);
	}

	/**
	 * @param name The project name
	 * @returns A promise with the pointcloud description
	 */
	async getPointClouds(name: string): Promise<WSPointCloudDesc[]> {
		const req = await this.#fetch(`${this.url}/pointcloud/${name}`);
		if (!req.ok) throw new FetchError("webshare", "pointcloud");
		const data = await req.text();
		return PointCloudConvert.toWSPointCloudDesc(data);
	}

	/**
	 * Get the description of all the scans for a project
	 *
	 * @param name The project name
	 * @returns The list of scans
	 */
	async getScans(name: string): Promise<WSScanDesc[]> {
		const req = await this.#fetch(`${this.url}/scan/${name}`);
		if (!req.ok) throw new FetchError("webshare", "scan");
		const data = await req.text();
		return ScanDescConvert.toWSScanDesc(data);
	}

	/**
	 * Compute the url to fetch a pano texture
	 *
	 * @param name The project name
	 * @param scanUid The uuid of the interested scan
	 * @param gray true if grayscale
	 * @param width The required image width
	 * @returns The url string
	 */
	computePanoTextureUrl(name: string, scanUid: string, gray: boolean, width: number): string {
		const tag = gray ? "gray" : "color";
		return `${this.url}/data/project/${name}/scan/${scanUid}/${tag}/${width}`;
	}

	/**
	 * Compute the url to fetch a pano depth texture
	 *
	 * @param name The project name
	 * @param scanUid The uuid of the interested scan
	 * @param width The required image width
	 * @returns The url string
	 */
	computePanoDepthUrl(name: string, scanUid: string, width: number): string {
		return `${this.url}/data/project/${name}/distance/${scanUid}/${width}`;
	}

	/**
	 * Request a pano depth texture
	 *
	 * @param name The project name
	 * @param scanUid The uuid of the scan we want
	 * @param width The requested width
	 * @returns A promise for the DataTexture with an abort controller to cancel it
	 */
	getPanoDepthTexture(name: string, scanUid: string, width: number): DepthRequest {
		const abort = new AbortController();
		const url = this.computePanoDepthUrl(name, scanUid, width);
		const promise = this.#fetch(url, { signal: abort.signal })
			.then((res) => {
				if (!res.ok) throw new FetchError("Webshare", "DepthTexture");
				return res.blob();
			})
			.then((blob) => blob.arrayBuffer())
			.then((buff) => {
				const arr = new Float32Array(buff);
				return new EquirectangularDepthImage(width, arr);
			});
		return { promise, abort };
	}

	/**
	 * Load a PanoProject to access info and the single pano images
	 *
	 * @param name The project name
	 * @returns The WSPanoProject object
	 */
	async getPanoProject(name: string): Promise<WSPanoProject> {
		const desc = (await this.getProjects()).find((x) => x.Name === name);
		if (!desc) throw new Error(`Project ${name} not found`);
		const scans = await this.getScans(name);
		return new WSPanoProject(this, desc, scans);
	}

	/**
	 *
	 * @param name Point cloud project name
	 * @param uuid Point cloud unique id
	 * @param entity Entity uuid of the pointcloud to query
	 * @param ignorePose true to ignore the webshare world matrix, useful for backward compatibility with the sphere viewer
	 * @returns A list of nodes
	 */
	async getKDTree(name: string, uuid: string, entity: string, ignorePose = false): Promise<WSKDTree> {
		const req = await this.#fetch(`${this.url}/pointcloudnode/${name}/${uuid}`);
		if (!req.ok) throw new FetchError("webshare", "pointcloudnode");
		const data = await req.text();
		return new WSKDTree(this, name, entity, TreeNodeConvert.toWSTreeNodes(data), ignorePose);
	}

	/**
	 * Query webshare for a single node data
	 *
	 * @param name The project name
	 * @param entity The entity uuid
	 * @param index The index of the node "rtx0x0x0"
	 * @param cdn The cdn manager to compute the correct url to use
	 * @returns An object to wait or cancel the fetch
	 */
	async getNode(name: string, entity: string, index: string, cdn?: WSCdnManager): Promise<WSNodeFetch> {
		let url = `${this.url}/entity/${name}/${entity}/data/blob/${index}.ptu`;
		if (cdn) {
			url = await cdn.computeCdnUrl(`${name}/entity/${entity}/${index}.ptu`);
		}
		const w = await loadWorker("WSNodes");
		return new WSNodeFetch(entity, index, url, w);
	}

	/**
	 * Query webshare for the signed urls to use to query a webshare project data
	 *
	 * @param name The name of the project
	 * @returns The cdn signatures
	 */
	async getSignedUrls(name: string): Promise<WSCdnSignatures> {
		const req = await this.#fetch(`${this.url}/data/signedurl/${name}`);
		if (!req.ok) throw new FetchError("webshare", "signedurls");
		const data = await req.json();
		if (!isWSCdnSignatures(data)) throw new FetchError("webshare", "signedurl");
		return data;
	}

	/**
	 * Private function to request an auth token
	 *
	 * @returns An auth token if the token provider is defined
	 */
	async #requestToken(): Promise<BearerToken | undefined> {
		if (!this.tokenProvider) return;
		return await this.tokenProvider();
	}

	/**
	 * Private version of fetch that will add the Auth header if a token is available
	 *
	 * @param input the url to fetch
	 * @param init the other fetch parameters
	 * @returns a Response with the result of the HTTP request
	 */
	#fetch: typeof fetch = async (input, init): Promise<Response> => {
		const token = await this.#requestToken();

		// If the token is missing or we're not talking to a webshare backend
		// do not send the Authorization header
		if (!this.url.includes("webshare") || !token) {
			return fetch(input, init);
		}

		return fetch(input, {
			...init,
			headers: {
				...init?.headers,
				Authorization: `Bearer ${token}`,
			},
		});
	};
}
