import { NodeCacheElement } from "./LodMultiview";

/**
 * Base class to determine which of the nodes that fall out of visibility are
 * allowed to stay in cache.
 */
export class LodCachingStrategy {
	/**
	 * Computes which nodes to remove from the cache if they aren't visible
	 *
	 * @param nodes the map of all the memory cached nodes that will be modified as nodes fail out of the cache
	 * @param visibleNodes the map of currently visible nodes, used for checking visibility of the cached nodes
	 * @returns The list of nodes to be removed from cache and therefore unloaded
	 */
	computeDisposableNodes(
		nodes: Map<number, NodeCacheElement>,
		visibleNodes: Map<number, NodeCacheElement>,
	): number[] {
		const nodesToDelete = new Array<number>();
		for (const key of nodes.keys()) {
			if (!visibleNodes.has(key)) {
				nodesToDelete.push(key);
			}
		}
		return nodesToDelete;
	}
}

/** Trivial caching policy to keep everything in cache, suitable for pano images.*/
export class LodCacheEverything extends LodCachingStrategy {
	static NOTHING: number[] = [];

	/**
	 * @param nodes the nodes in cache
	 * @param visibleNodes the visible nodes
	 * @returns always the empty list to signal that everything should be cached.
	 */
	override computeDisposableNodes(
		// eslint-disable-next-line unused-imports/no-unused-vars
		nodes: Map<number, NodeCacheElement>,
		// eslint-disable-next-line unused-imports/no-unused-vars
		visibleNodes: Map<number, NodeCacheElement>,
	): number[] {
		return LodCacheEverything.NOTHING;
	}
}

/** Clean up nodes if they are unrendered for more than some time. */
export class LodCachingStrategyTime extends LodCachingStrategy {
	unrenderedTimeLimit: number;

	/**
	 * @param timeLimit Time in ms that a node can remain unrendered before it is cleaned up
	 */
	constructor(timeLimit = 10000) {
		super();
		this.unrenderedTimeLimit = timeLimit;
	}
	/**
	 * Computes which nodes to remove from the cache if they aren't visible
	 * Nodes that haven't been visible in some time are removed.
	 *
	 * @param nodes the map of all the memory cached nodes that will be modified as nodes fail out of the cache
	 * @param visibleNodes the map of currently visible nodes, used for checking visibility of the cached nodes
	 * @returns The list of nodes to be removed from cache and therefore unloaded
	 */
	override computeDisposableNodes(
		nodes: Map<number, NodeCacheElement>,
		visibleNodes: Map<number, NodeCacheElement>,
	): number[] {
		const nodesToDelete = new Array<number>();
		const now = performance.now();
		for (const [key, node] of nodes) {
			if (visibleNodes.has(key)) {
				node.lastRenderedTime = now;
			} else if (now - node.lastRenderedTime > this.unrenderedTimeLimit) {
				nodesToDelete.push(key);
			}
		}
		return nodesToDelete;
	}
}

/** Clean up nodes if there are too many unrendered chunks in memory. */
export class LodCachingStrategyMaxChunks extends LodCachingStrategy {
	/**
	 * The maximum amount of not visible nodes to keep in cache.
	 */
	maxChunks: number;

	/**
	 * @param maxChunks The maximum number of chunks that we will keep in memory, only non visible chunks will be removed
	 */
	constructor(maxChunks = 500) {
		super();
		this.maxChunks = maxChunks;
	}
	/**
	 * If there are too many cached nodes, the oldest ones should be removed,
	 * the ones with recent access should be kept.
	 *
	 * @param nodes the map of all the memory cached nodes that will be modified as nodes fail out of the cache
	 * @param visibleNodes the map of currently visible nodes, used for checking visibility of the cached nodes
	 * @returns The list of nodes to be removed from cache and therefore unloaded
	 */
	override computeDisposableNodes(
		nodes: Map<number, NodeCacheElement>,
		visibleNodes: Map<number, NodeCacheElement>,
	): number[] {
		const numberToDelete = nodes.size - this.maxChunks;
		if (numberToDelete <= 0) {
			return [];
		}
		const nodesToDelete = new Array<{ id: number; time: number }>();
		const now = performance.now();
		for (const [key, node] of nodes) {
			if (visibleNodes.has(key)) {
				node.lastRenderedTime = now;
			} else {
				nodesToDelete.push({ id: key, time: node.lastRenderedTime });
			}
		}
		nodesToDelete.sort((a, b) => a.time - b.time);
		const result = new Array<number>();
		for (let index = 0; index < numberToDelete && index < nodesToDelete.length; index++) {
			result.push(nodesToDelete[index].id);
		}
		return result;
	}
}
