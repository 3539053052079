import {
  CursorStyle,
  LocationTransparent,
  useCustomCursor,
} from "@faro-lotv/flat-ui";
import {
  FullScreenQuad,
  memberWithPrivateData,
  pixels2m,
} from "@faro-lotv/lotv";
import {
  Quaternion as QuaternionProps,
  ThreeEvent,
  Vector3 as Vector3Props,
  useFrame,
  useThree,
} from "@react-three/fiber";
import {
  PropsWithChildren,
  RefObject,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Group,
  MOUSE,
  Matrix4,
  Mesh,
  Object3D,
  Quaternion,
  Vector2,
  Vector3,
  Vector3Tuple,
} from "three";
import { useNonExhaustiveEffect } from "../../hooks";
import {
  useIsKeyPressed,
  useKeyboardEvents,
  useThreeEventTarget,
  useViewportRef,
} from "../hooks";
import { useSvg } from "../hooks/use-svg";
import { PinSprite } from "../utils/pin-sprite";

/**
 * Additional mouse button values for checking event.buttons
 * https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
 */
export const MOUSE_BUTTONS = {
  PRIMARY: 1,
  SECONDARY: 2,
};

// A min length used if some length is computed as zero when the user move pivot to the exact same position
const LENGTH_EPSILON = 0.001;

/** External action that can be triggered on a TwoPointAlignment component */
export type TwoPointAlignmentActions = {
  /** Try to drop a pin in a specific 3d world position */
  tryDropPin(position: Vector3): void;
};

export type TwoPointAlignmentProps = PropsWithChildren & {
  // Callback for when the pointer is pressed down on a handle
  onPointerDown?(event: ThreeEvent<PointerEvent>): void;
  // Callback for when a handle is released
  onPointerUp?(event: ThreeEvent<PointerEvent>): void;
  // Callback for when the transform changes
  onTransformChanged?(
    position: Vector3,
    rotation: Quaternion,
    scale: Vector3,
  ): void;
  /** Callback for when the pins count changes. */
  onPinsCountChanged?(newCount: number): void;
  position: Vector3Props;
  quaternion: QuaternionProps;
  scale: Vector3Props;

  /** Size of the pins in pixels (Default: 40) */
  pinSize?: number;

  /** Opacity of the pins (Default: 0.9) */
  pinOpacity?: number;

  /**
   * True if the alignemt should allow changing the chilrens scale, otherwise only position and rotation is allowed.
   *
   * @default true
   */
  isScaleEnabled?: boolean;

  /**
   * If set to true, do not squash data. For clouds there is no way apply clipping planes before squash
   * it makes impossible using cross-sections data for TwoPointAlignment with clouds
   *
   * @default false
   */
  disableDataSquash?: boolean;

  /**
   * Enable the interaction
   *
   * @default true
   */
  enabled?: boolean;

  /** A handle on the action objects to modify the TwoPointAlignments */
  actions?: RefObject<TwoPointAlignmentActions>;

  /** The camera altitude, needed to compute the transform that 'squashes' the data along Y */
  cameraAltitude?: number;
};

/**
 * R3F attach a target property to events that's not reflected in the types but mirror a target for pointer captures
 *
 * @see https://github.com/pmndrs/react-three-fiber/blob/76d30f1490a35ec53757af46aeb0fa9406dbb1f3/packages/fiber/src/core/events.ts#L312
 */
type R3FEventTarget = {
  hasPointerCapture(id: number): void;
  setPointerCapture(id: number): void;
  releasePointerCapture(id: number): void;
};

/**
 * Function to check if a R3F events has the target property (Note: it always has)
 *
 * @param event The R3F event
 * @returns true as all R3F events have the target property
 */
export function eventWithTarget(
  event: ThreeEvent<PointerEvent>,
): event is ThreeEvent<PointerEvent> & { target: R3FEventTarget } {
  return true;
}

// The sprite's anchor point, and the point around which the sprite rotates.
// Makes sure that the texture appears centered horizontally but on top of the sprite world positions
// small vertical shift required in order that click without mouse movement after initial placement will happen inside sprite
const SPRITE_CENTER = new Vector2(0.5, 0.1);

/**
 * @returns An object that used for 2D positioning, rotation, and scaling of another object from a top down perspective.
 */
export function TwoPointAlignment({
  onPointerDown,
  onPointerUp,
  children,
  onTransformChanged,
  onPinsCountChanged,
  position,
  quaternion,
  scale,
  pinSize = 40,
  pinOpacity = 1,
  isScaleEnabled = true,
  disableDataSquash = false,
  enabled = true,
  actions,
  cameraAltitude,
}: TwoPointAlignmentProps): JSX.Element {
  const pinTexture = useSvg(LocationTransparent, 512, 512);

  // A bunch of references to the objects and data for real time manipulation.

  /** Reference to the first pin */
  const pin1Ref = useRef<Mesh>(null);
  /** Reference to the second pin */
  const pin2Ref = useRef<Mesh>(null);
  /** Reference to the full quad used to pick pins and drag with SHIFT key */
  const dragPlaneRef = useRef<FullScreenQuad>(null);
  /** Reference to the data manipulated by the user (e.g. CAD, PointClouds, Trajectories, ...) */
  const dataGroupRef = useRef<Group>(null);
  /** 2D coordinates on screen, used to discriminate between a click and a drag */
  const clientCoords = useRef<Vector2>(new Vector2());
  /** The current target for a dragging action: it can be the dragging plane or a pin */
  const dragTarget = useRef<unknown>(null);
  /** The 3D point in world coordinate picked at the start of any interaction (mouse down events) */
  const sPoint = useRef<Vector3>(new Vector3());
  /** The 3D point in world coordinate that tracks the current mouse position (mouse move events) */
  const ePoint = useRef<Vector3>(new Vector3());
  /** A flag describing if the current pose changed */
  const hasPoseChanged = useRef<boolean>(false);
  /** An object storing temporay data to avoid reallocations */
  const TEMP = useMemo(
    () => ({
      VEC3_1: new Vector3(),
      VEC3_2: new Vector3(),
      VEC3_3: new Vector3(),
      VEC3_4: new Vector3(),
      VEC3_5: new Vector3(),
    }),
    [],
  );

  const camera = useThree((s) => s.camera);

  const [isPin1Visible, setIsPin1Visible] = useState(false);
  const [isPin2Visible, setIsPin2Visible] = useState(false);

  const pinCount = (isPin1Visible ? 1 : 0) + (isPin2Visible ? 1 : 0);

  // Should only listen for changes of pin count
  useNonExhaustiveEffect(() => {
    onPinsCountChanged?.(pinCount);
  }, [pinCount]);

  // Keep track of the element on which the dragging event is happening
  const startDrag = useCallback((e: ThreeEvent<PointerEvent>) => {
    if (!dragTarget.current) dragTarget.current = e.eventObject;
  }, []);

  // Reset the drag element
  const endDrag = useCallback(() => {
    if (!dragTarget.current) return;
    dragTarget.current = null;
  }, []);

  // Compute the 3D coordinates of a point picked, while forcing the Y
  // coordinate to always be on the near plane
  const computeEventPoint = useCallback(
    (e: ThreeEvent<PointerEvent> | ThreeEvent<MouseEvent>, p: Vector3) =>
      p.copy(e.unprojectedPoint).setY(camera.position.y - camera.near),
    [camera],
  );

  // Translate the data, by computing the displacement between the start and end points
  const singlePointDrag = useCallback(
    (e: ThreeEvent<PointerEvent>): void => {
      const newPos = computeEventPoint(e, TEMP.VEC3_1);
      const displacement = TEMP.VEC3_2.copy(newPos).sub(sPoint.current);

      const previousMovement = TEMP.VEC3_3.subVectors(
        ePoint.current,
        sPoint.current,
      );

      if (isPin1Visible) {
        pin1Ref.current?.position.sub(previousMovement).add(displacement);
      }
      if (isPin2Visible) {
        pin2Ref.current?.position.sub(previousMovement).add(displacement);
      }

      // Translate the data
      if (dataGroupRef.current) {
        dataGroupRef.current.position.sub(previousMovement).add(displacement);
      }

      ePoint.current.copy(newPos);

      hasPoseChanged.current = true;
    },
    [computeEventPoint, TEMP, isPin1Visible, isPin2Visible],
  );

  // Both points are visible, so one is treated as the anchor, while the other is dragged to calculate rotation and scale
  const twoPointDrag = useCallback(
    (e: ThreeEvent<PointerEvent>, anchorObject: Object3D) => {
      const isDraggingPin = isPin1Visible && isPin2Visible;
      const currentPosition = computeEventPoint(e, TEMP.VEC3_1);

      const anchorPosition = anchorObject.getWorldPosition(TEMP.VEC3_4);

      TEMP.VEC3_5.copy(isDraggingPin ? e.eventObject.position : ePoint.current);

      // If both pins are visible, we scale the data, by computing the ratio
      // between the current and the previous displacement of the mouse position
      // while preserving the offset from the mouse position to the dragged pin
      if (isDraggingPin) {
        currentPosition.sub(ePoint.current).add(TEMP.VEC3_5);
        if (dataGroupRef.current) {
          const matrix = scaleAround(
            anchorPosition,
            TEMP.VEC3_5,
            currentPosition,
          );
          dataGroupRef.current.applyMatrix4(matrix);
          e.eventObject.position.applyMatrix4(matrix);
        }
      }

      // Rotate the group around the anchor by the angle that the mouse moved
      if (dataGroupRef.current) {
        const matrix = rotateAround(
          anchorPosition,
          TEMP.VEC3_5,
          currentPosition,
        );
        dataGroupRef.current.applyMatrix4(matrix);
        e.eventObject.position.applyMatrix4(matrix);
      }

      computeEventPoint(e, ePoint.current);
      hasPoseChanged.current = true;
    },
    [
      isPin1Visible,
      isPin2Visible,
      computeEventPoint,
      TEMP.VEC3_1,
      TEMP.VEC3_4,
      TEMP.VEC3_5,
    ],
  );

  // Update the store with the current transformation
  const executeTransformCallback = useCallback(() => {
    if (dataGroupRef.current && hasPoseChanged.current) {
      onTransformChanged?.(
        dataGroupRef.current.position,
        dataGroupRef.current.quaternion,
        dataGroupRef.current.scale,
      );
      hasPoseChanged.current = false;
    }
  }, [onTransformChanged]);

  // Handle pointer down on the background plane: initialize the variables needed for the interaction
  const onDragPlanePointerDown = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (!enabled) return;
      if (e.nativeEvent.button !== MOUSE.LEFT) return;
      // Workaround to avoid receiving events when clicking on a button in overlay
      // TODO: https://faro01.atlassian.net/browse/SWEB-2093
      if (
        !(
          e.nativeEvent.target instanceof HTMLCanvasElement ||
          e.nativeEvent.target instanceof HTMLDivElement
        )
      ) {
        return;
      }

      clientCoords.current.set(e.clientX, e.clientY);
      computeEventPoint(e, sPoint.current);
      computeEventPoint(e, ePoint.current);
      startDrag(e);

      onPointerDown?.(e);

      if (eventWithTarget(e)) {
        e.target.setPointerCapture(e.nativeEvent.pointerId);
      }

      e.stopPropagation();
    },
    [computeEventPoint, enabled, onPointerDown, startDrag],
  );

  // Create a new pin (if possible)
  const tryDropPin = useCallback(
    (position: Vector3): void => {
      let allowSetPin = true;
      if (isPin1Visible || isPin2Visible) {
        const existingPinRef = isPin1Visible ? pin1Ref : pin2Ref;
        const dist =
          existingPinRef.current?.position.distanceTo(position) ?? -1;

        // we should not allow set two pins at exactly same place.
        // That case has no practical value and creates problem while dragging those pins
        allowSetPin = dist > 0;
      }

      if (allowSetPin) {
        // Drop pin 1 if it is not visible, otherwise drop pin 2 if scaling is enabled
        if (!isPin1Visible) {
          pin1Ref.current?.position.copy(position);
          setIsPin1Visible(true);
        } else if (!isPin2Visible && isScaleEnabled) {
          pin2Ref.current?.position.copy(position);
          setIsPin2Visible(true);
        }
      }
    },
    [isPin1Visible, isPin2Visible, isScaleEnabled],
  );

  useImperativeHandle(actions, () => ({ tryDropPin }));

  // Attempt to drop a pin
  const onDropPin = useCallback(
    (e: ThreeEvent<MouseEvent>): void => {
      // Don't drop pins on a drag (if we moved the mouse since mouse down)
      if (
        Math.abs(e.clientX - clientCoords.current.x) <= 2 &&
        Math.abs(e.clientY - clientCoords.current.y) <= 2
      ) {
        const pinPosition = computeEventPoint(e, TEMP.VEC3_1);
        tryDropPin(pinPosition);
      }
    },
    [computeEventPoint, tryDropPin, TEMP],
  );

  // Handle pointer up on the background plane potentially dropping a pin and ending the drag
  const onDragPlanePointerUp = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (!enabled) return;

      onPointerUp?.(e);
      e.stopPropagation();
      if (eventWithTarget(e)) {
        e.target.releasePointerCapture(e.nativeEvent.pointerId);
      }

      if (e.nativeEvent.button !== MOUSE.LEFT || !dragPlaneRef.current) {
        return;
      }

      if (dragTarget.current !== dragPlaneRef.current) {
        return;
      }

      // Workaround to avoid receiving events when clicking on a button in overlay
      // TODO: https://faro01.atlassian.net/browse/SWEB-2093
      if (
        !(
          e.nativeEvent.target instanceof HTMLCanvasElement ||
          e.nativeEvent.target instanceof HTMLDivElement
        )
      ) {
        return;
      }

      executeTransformCallback();
      endDrag();
    },
    [enabled, endDrag, executeTransformCallback, onPointerUp],
  );

  // Handle pointer move on the background plane, so we can translate or rotate the data as appropriate
  const onDragPlanePointerMove = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (!enabled) return;

      // Make sure all the refs are set, and we are dealing the the Left "Primary" mouse button
      // And that we started the drag on the background plane
      if (
        !pin1Ref.current ||
        !pin2Ref.current ||
        e.nativeEvent.buttons !== MOUSE_BUTTONS.PRIMARY ||
        dragTarget.current !== dragPlaneRef.current
      ) {
        return;
      }
      // if exactly 1 pin is visible and no shift key has been pressed, two point drag using the mouse as the second point
      if (isPin1Visible !== isPin2Visible && !e.nativeEvent.shiftKey) {
        twoPointDrag(e, isPin1Visible ? pin1Ref.current : pin2Ref.current);
      } else if (e.nativeEvent.shiftKey) {
        singlePointDrag(e);
      }
    },
    [enabled, isPin1Visible, isPin2Visible, twoPointDrag, singlePointDrag],
  );

  // Handle pointer down on either pin, used to capture the initial state before dragging starts.
  const onPinPointerDown = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (!enabled) return;
      if (e.nativeEvent.button === MOUSE.LEFT && e.eventObject.visible) {
        e.stopPropagation();

        onPointerDown?.(e);

        if (eventWithTarget(e)) {
          e.target.setPointerCapture(e.nativeEvent.pointerId);
        }

        clientCoords.current.set(e.clientX, e.clientY);
        computeEventPoint(e, sPoint.current);
        computeEventPoint(e, ePoint.current);
        startDrag(e);
      }
    },
    [computeEventPoint, enabled, onPointerDown, startDrag],
  );

  // Handle pointer up on either pin
  const onPinPointerUp = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (!enabled) return;
      onPointerUp?.(e);
      e.stopPropagation();

      if (eventWithTarget(e)) {
        e.target.releasePointerCapture(e.nativeEvent.pointerId);
      }

      if (!e.eventObject.visible) {
        return;
      }

      executeTransformCallback();
      endDrag();
    },
    [enabled, endDrag, executeTransformCallback, onPointerUp],
  );

  const removePin1 = useCallback((e: ThreeEvent<MouseEvent>) => {
    if (!e.eventObject.visible) return;
    setIsPin1Visible(false);
    e.stopPropagation();
  }, []);

  const removePin2 = useCallback((e: ThreeEvent<MouseEvent>) => {
    if (!e.eventObject.visible) return;
    setIsPin2Visible(false);
    e.stopPropagation();
  }, []);

  // Handle pointer drag on pin 1
  const onPin1PointerMove = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (!enabled) return;
      if (
        e.nativeEvent.buttons === MOUSE_BUTTONS.PRIMARY &&
        isPin1Visible &&
        dragTarget.current === pin1Ref.current
      ) {
        e.stopPropagation();
        if (pin2Ref.current && isPin2Visible && !e.nativeEvent.shiftKey) {
          twoPointDrag(e, pin2Ref.current);
        } else {
          singlePointDrag(e);
        }
      }
    },
    [enabled, isPin1Visible, isPin2Visible, twoPointDrag, singlePointDrag],
  );

  // Handle pointer drag on pin 2
  const onPin2PointerMove = useCallback(
    (e: ThreeEvent<PointerEvent>) => {
      if (!enabled) return;
      if (
        e.nativeEvent.buttons === MOUSE_BUTTONS.PRIMARY &&
        isPin2Visible &&
        dragTarget.current === pin2Ref.current
      ) {
        e.stopPropagation();
        if (pin1Ref.current && isPin1Visible && !e.nativeEvent.shiftKey) {
          twoPointDrag(e, pin1Ref.current);
        } else {
          singlePointDrag(e);
        }
      }
    },
    [enabled, isPin2Visible, isPin1Visible, twoPointDrag, singlePointDrag],
  );

  // Get the correct viewport size if we're in a View
  const viewport = useViewportRef();

  // Update the pin scale at every frame to keep it in pixel size
  useFrame(({ size, camera }) => {
    if (pin1Ref.current && pin2Ref.current) {
      // Compute a scale factor to account from the distance to the camera
      // to keep the pins at a fixed pixel size
      const height = viewport.current?.height ?? size.height;
      const dist = pin1Ref.current
        .getWorldPosition(TEMP.VEC3_1)
        .distanceTo(camera.position);
      const camDistanceScaleFactor = pixels2m(pinSize, camera, height, dist);

      // Compute the pin scale taking into account the scale applied to the entire group
      const scale =
        pin1Ref.current.parent?.getWorldScale(TEMP.VEC3_1) ??
        TEMP.VEC3_1.set(1, 1, 1);
      pin1Ref.current.scale
        .set(1 / scale.x, 1 / scale.y, 1 / scale.z)
        .multiplyScalar(camDistanceScaleFactor);
      pin2Ref.current.scale.copy(pin1Ref.current.scale);
    }
  }, 0);

  // Compute the pose of the group wrapping the data, that allows the data to be squashed in the Y direction
  // while keeping it at the same Y coordinate
  const scaleResetPose = useMemo(() => {
    const altitude = cameraAltitude ?? camera.position.y;
    const pose: { position: Vector3Tuple; scale: Vector3Tuple } =
      disableDataSquash
        ? {
            position: [0, 0, 0],
            scale: [1, 1, 1],
          }
        : {
            position: [0, altitude - camera.near - 0.1, 0],
            scale: [1, 0, 1],
          };
    return pose;
  }, [disableDataSquash, camera, cameraAltitude]);

  const {
    isOverPin: isOverPin1,
    onPointerEnter: onPin1PointerEnter,
    onPointerLeave: onPin1PointerLeave,
  } = useIsOverPin(isPin1Visible);
  const {
    isOverPin: isOverPin2,
    onPointerEnter: onPin2PointerEnter,
    onPointerLeave: onPin2PointerLeave,
  } = useIsOverPin(isPin2Visible);

  useTwoPointAlignmentCursor(enabled, pinCount, isOverPin1 || isOverPin2);

  return (
    <group>
      <group {...scaleResetPose}>
        <group
          ref={dataGroupRef}
          position={position}
          quaternion={quaternion}
          scale={scale}
        >
          {children}
        </group>
      </group>
      <fullScreenQuad
        ref={dragPlaneRef}
        visible={false}
        onPointerDown={onDragPlanePointerDown}
        onPointerUp={onDragPlanePointerUp}
        onPointerMove={onDragPlanePointerMove}
        onClick={onDropPin}
      />
      <object3D
        ref={pin1Ref}
        visible={isPin1Visible}
        onPointerDown={onPinPointerDown}
        onPointerUp={onPinPointerUp}
        onPointerMove={onPin1PointerMove}
        onPointerEnter={onPin1PointerEnter}
        onPointerLeave={onPin1PointerLeave}
        onContextMenu={removePin1}
        name="Pin1"
      >
        <PinSprite
          opacity={pinOpacity}
          center={SPRITE_CENTER}
          texture={pinTexture}
        />
      </object3D>
      <object3D
        ref={pin2Ref}
        visible={isPin2Visible}
        onPointerDown={onPinPointerDown}
        onPointerUp={onPinPointerUp}
        onPointerMove={onPin2PointerMove}
        onPointerEnter={onPin2PointerEnter}
        onPointerLeave={onPin2PointerLeave}
        onContextMenu={removePin2}
        name="Pin2"
      >
        <PinSprite
          opacity={pinOpacity}
          center={SPRITE_CENTER}
          texture={pinTexture}
        />
      </object3D>
    </group>
  );
}

//
/**
 * Rotate the group around the anchor by the angle that the mouse moved
 *
 * @param anchorPosition The 3D position the around which we want to rotate
 * @param startPoint The starting 3D position of the interaction
 * @param endPoint The ending 3D position of the interaction
 * @returns The resulting matrix
 */
const rotateAround = memberWithPrivateData(() => {
  const TEMP_VEC2_1 = new Vector2();
  const TEMP_VEC2_2 = new Vector2();
  const TEMP_MAT4_1 = new Matrix4();
  const TEMP_MAT4_2 = new Matrix4();
  const TEMP_MAT4_3 = new Matrix4();

  return (
    anchorPosition: Vector3,
    startPoint: Vector3,
    endPoint: Vector3,
  ): Matrix4 => {
    const initialDirection = TEMP_VEC2_1.set(
      startPoint.x - anchorPosition.x,
      startPoint.z - anchorPosition.z,
    ).normalize();
    const currentDirection = TEMP_VEC2_2.set(
      endPoint.x - anchorPosition.x,
      endPoint.z - anchorPosition.z,
    ).normalize();

    // Rotating clockwise or counterclockwise
    const rotationDirection =
      initialDirection.cross(currentDirection) < 0 ? 1 : -1;
    // Find the magnitude of the rotation
    const dotProduct = Math.min(
      1,
      Math.max(-1, initialDirection.dot(currentDirection)),
    );
    // Compute the angle
    let theta = rotationDirection * Math.acos(dotProduct);
    // Clamp to 2 PI (One Rotation)
    if (theta > Math.PI * 2) {
      theta -= Math.PI * 2;
    } else if (theta < -Math.PI * 2) {
      theta += Math.PI * 2;
    }

    // Compute the final matrix by first translating the object at the origin,
    // rotating it around Y and bringing it back to its position
    return TEMP_MAT4_1.makeTranslation(
      anchorPosition.x,
      anchorPosition.y,
      anchorPosition.z,
    )
      .multiply(TEMP_MAT4_2.makeRotationY(theta))
      .multiply(
        TEMP_MAT4_3.makeTranslation(
          -anchorPosition.x,
          -anchorPosition.y,
          -anchorPosition.z,
        ),
      );
  };
});

/**
 * Scale the group around the anchor by the length that the mouse moved
 *
 * @param anchorPosition The 3D position the will remain in the same position when scaling
 * @param startPoint The starting 3D position of the interaction
 * @param endPoint The ending 3D position of the interaction
 * @returns The matrix containing the final transformation
 */
const scaleAround = memberWithPrivateData(() => {
  const TEMP_VEC3_1 = new Vector3();
  const TEMP_VEC3_2 = new Vector3();
  const TEMP_MAT4_1 = new Matrix4();
  const TEMP_MAT4_2 = new Matrix4();
  const TEMP_MAT4_3 = new Matrix4();
  return (
    anchorPosition: Vector3,
    startPoint: Vector3,
    endPoint: Vector3,
  ): Matrix4 => {
    // Compute the final matrix by first translating the object at the origin,
    // scaling it and bringing it back to its position
    const startLength =
      TEMP_VEC3_1.subVectors(startPoint, anchorPosition).length() ||
      LENGTH_EPSILON;
    const endLength =
      TEMP_VEC3_2.subVectors(endPoint, anchorPosition).length() ||
      LENGTH_EPSILON;
    const scaleFactor = endLength / startLength;

    // Compute the final matrix by first translating the object at the origin,
    // scaling it and bringing it back to its position
    return TEMP_MAT4_1.makeTranslation(
      anchorPosition.x,
      anchorPosition.y,
      anchorPosition.z,
    )
      .multiply(TEMP_MAT4_2.makeScale(scaleFactor, scaleFactor, scaleFactor))
      .multiply(
        TEMP_MAT4_3.makeTranslation(
          -anchorPosition.x,
          -anchorPosition.y,
          -anchorPosition.z,
        ),
      );
  };
});

/**
 * Hook to convert pointer enter/leave events into reactive hover state.
 *
 * @param isVisible whether the pin is visible. A hidden pin is not considered hover-able.
 * @returns a reactive boolean indicating the current hover state, and event callbacks to attach the element of interest
 */
function useIsOverPin(isVisible: boolean): {
  isOverPin: boolean;
  onPointerEnter(): void;
  onPointerLeave(): void;
} {
  const [isOverPin, setIsOverPin] = useState(false);

  const onPointerEnter = useCallback(() => setIsOverPin(true), []);
  const onPointerLeave = useCallback(() => setIsOverPin(false), []);

  return { isOverPin: isOverPin && isVisible, onPointerEnter, onPointerLeave };
}

function useTwoPointAlignmentCursor(
  enable: boolean,
  pinCount: number,
  isOverPin: boolean,
): void {
  // Watch the document body, since it doesn't matter where the shift modifier is pressed
  const keyboardEvents = useKeyboardEvents(document.body);
  const isShiftPressed = useIsKeyPressed(keyboardEvents, "Shift");

  let cursorToShow = CursorStyle.default;

  if (isShiftPressed) {
    cursorToShow = CursorStyle.translation;
  } else if (pinCount === 1) {
    cursorToShow = isOverPin ? CursorStyle.translation : CursorStyle.rotation;
  } else if (pinCount === 2) {
    cursorToShow = isOverPin ? CursorStyle.scale : CursorStyle.default;
  }

  const domElement = useThreeEventTarget();
  useCustomCursor({
    enable,
    cursorToShow,
    domElement,
  });
}
