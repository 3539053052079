varying vec2 vUv;

#include <clipping_planes_pars_vertex>

void main() {
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.f);
    // offset the floor plan 20mm so it won't be blocked by CAD or Points aligned at the same position
    mvPosition.z += 0.02;
    gl_Position = projectionMatrix * mvPosition;

    #include <clipping_planes_vertex>
}
